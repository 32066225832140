import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePerk, deletePerk, createPerk, fetchUserPerks } from '../redux/slices/perkSlice';
import { showToast } from '../utils/toast';
import { 
  Card, 
  CardHeader, 
  CardContent, 
  CardFooter, 
  CardTitle, 
  CardDescription 
} from "./ui/card";
import { Gift, Edit2, Trash2, Plus, X, Save } from 'lucide-react';

const PerkForm = ({ isEditing, initialData, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState(initialData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Perk Title
        </label>
        <input
          name="title"
          type="text"
          value={formData.title}
          onChange={handleChange}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
          placeholder="e.g., Monthly Gym Membership"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleChange}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          rows={3}
          required
          placeholder="Describe your perk in detail..."
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Monthly Value ($)
        </label>
        <input
          name="estimatedValue"
          type="number"
          value={formData.estimatedValue}
          onChange={handleChange}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
          min="0"
          step="0.01"
          placeholder="100"
        />
      </div>

      <div className="flex justify-end gap-3 pt-4">
        {onCancel && (
          <button
            type="button"
            onClick={onCancel}
            className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            <X className="w-4 h-4 mr-2" />
            Cancel
          </button>
        )}
        <button
          type="submit"
          className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
        >
          {isEditing ? (
            <>
              <Save className="w-4 h-4 mr-2" />
              Save Changes
            </>
          ) : (
            <>
              <Plus className="w-4 h-4 mr-2" />
              Create Perk
            </>
          )}
        </button>
      </div>
    </form>
  );
};

const MyPerks = () => {
  const dispatch = useDispatch();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [editingPerk, setEditingPerk] = useState(null);
  
  const userPerks = useSelector((state) => state.perks.items.filter(
    perk => perk.userId._id === state.auth.user?._id
  ));
  const loading = useSelector((state) => state.perks.loading);

  useEffect(() => {
    dispatch(fetchUserPerks());
  }, [dispatch]);

  const handleCreateSubmit = async (formData) => {
    try {
      await showToast.promise(
        dispatch(createPerk(formData)).unwrap(),
        {
          loading: 'Creating perk...',
          success: 'Perk created successfully!',
          error: 'Failed to create perk'
        }
      );
      setShowCreateForm(false);
    } catch (error) {
      console.error('Error creating perk:', error);
    }
  };

  const handleUpdateSubmit = async (formData) => {
    try {
      await showToast.promise(
        dispatch(updatePerk({ 
          perkId: editingPerk, 
          updateData: formData 
        })).unwrap(),
        {
          loading: 'Updating perk...',
          success: 'Perk updated successfully',
          error: 'Failed to update perk'
        }
      );
      setEditingPerk(null);
    } catch (error) {
      console.error('Error updating perk:', error);
    }
  };

  const handleDelete = async (perkId) => {
    if (window.confirm('Are you sure you want to delete this perk?')) {
      try {
        await showToast.promise(
          dispatch(deletePerk(perkId)).unwrap(),
          {
            loading: 'Deleting perk...',
            success: 'Perk deleted successfully',
            error: 'Failed to delete perk'
          }
        );
      } catch (error) {
        console.error('Error deleting perk:', error);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-3xl font-bold text-gray-900">My Perks</h2>
        {!showCreateForm && (
          <button
            onClick={() => setShowCreateForm(true)}
            className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            <Plus className="w-4 h-4 mr-2" />
            Add New Perk
          </button>
        )}
      </div>

      {showCreateForm && (
        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Add New Perk</CardTitle>
            <CardDescription>
              Share details about the perk you'd like to exchange
            </CardDescription>
          </CardHeader>
          <CardContent>
            <PerkForm
              isEditing={false}
              initialData={{ title: '', description: '', estimatedValue: '' }}
              onSubmit={handleCreateSubmit}
              onCancel={() => setShowCreateForm(false)}
            />
          </CardContent>
        </Card>
      )}

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {userPerks.map((perk) => (
          <Card 
            key={perk._id} 
            className="group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
          >
            {editingPerk === perk._id ? (
              <CardContent className="pt-6">
                <PerkForm
                  isEditing={true}
                  initialData={{
                    title: perk.title,
                    description: perk.description,
                    estimatedValue: perk.estimatedValue
                  }}
                  onSubmit={handleUpdateSubmit}
                  onCancel={() => setEditingPerk(null)}
                />
              </CardContent>
            ) : (
              <>
                <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-2">
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-blue-50 rounded-lg">
                      <Gift className="w-6 h-6 text-blue-500" />
                    </div>
                    <div>
                      <CardTitle className="text-lg font-semibold">
                        {perk.title}
                      </CardTitle>
                      <CardDescription>
                        Your perk
                      </CardDescription>
                    </div>
                  </div>
                  <div className="text-xl font-bold text-blue-600">
                    ${perk.estimatedValue}
                  </div>
                </CardHeader>

                <CardContent>
                  <p className="text-gray-600 text-sm">{perk.description}</p>
                </CardContent>

                <CardFooter className="justify-end space-x-2">
                  <button
                    onClick={() => setEditingPerk(perk._id)}
                    className="flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    <Edit2 className="w-4 h-4 mr-2" />
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(perk._id)}
                    className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
                  >
                    <Trash2 className="w-4 h-4 mr-2" />
                    Delete
                  </button>
                </CardFooter>
              </>
            )}
          </Card>
        ))}
      </div>

      {userPerks.length === 0 && !showCreateForm && (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <Gift className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            No perks added yet
          </h3>
          <p className="text-gray-600 mb-4">
            Start by adding your first company perk to exchange
          </p>
          <button
            onClick={() => setShowCreateForm(true)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            <Plus className="w-4 h-4 mr-2" />
            Add Your First Perk
          </button>
        </div>
      )}
    </div>
  );
};

export default MyPerks;