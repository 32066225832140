// frontend/src/redux/slices/chatSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const fetchMessages = createAsyncThunk(
  'chat/fetchMessages',
  async (exchangeId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/chat/${exchangeId}`);
      return { exchangeId, messages: response.data };
    } catch (error) {
      console.error('Error fetching messages:', {
        status: error.response?.status,
        message: error.response?.data?.message || error.message,
        error
      });
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch messages');
    }
  }
);

export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async ({ exchangeId, content }, { rejectWithValue }) => {
    try {
      const response = await api.post('/chat', { exchangeId, content });
      return { exchangeId, message: response.data };
    } catch (error) {
      console.error('Error sending message:', {
        status: error.response?.status,
        message: error.response?.data?.message || error.message,
        error
      });
      return rejectWithValue(error.response?.data?.message || 'Failed to send message');
    }
  }
);

export const markMessagesRead = createAsyncThunk(
  'chat/markMessagesRead',
  async (exchangeId, { rejectWithValue }) => {
    try {
      await api.post(`/chat/${exchangeId}/read`);
      return exchangeId;
    } catch (error) {
      console.error('Error marking messages as read:', {
        status: error.response?.status,
        message: error.response?.data?.message || error.message,
        error
      });
      return rejectWithValue(error.response?.data?.message || 'Failed to mark messages as read');
    }
  }
);

export const fetchUnreadCounts = createAsyncThunk(
  'chat/fetchUnreadCounts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/chat/unread');
      return response.data;
    } catch (error) {
      // Don't show error for rate limiting
      if (error.response?.status === 429) {
        return rejectWithValue('Rate limit exceeded');
      }
      
      // If it's a 500 error, return a more specific error message
      if (error.response?.status === 500) {
        return rejectWithValue('Server error while fetching messages. Please try again later.');
      }
      
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch unread counts');
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    messages: {},
    unreadCounts: {},
    loading: false,
    error: null,
    messageError: null,
    sendingMessage: false,
  },
  reducers: {
    clearMessages: (state, action) => {
      if (action.payload) {
        delete state.messages[action.payload];
      } else {
        state.messages = {};
      }
    },
    clearUnreadCount: (state, action) => {
      if (action.payload) {
        delete state.unreadCounts[action.payload];
      } else {
        state.unreadCounts = {};
      }
    },
    updateUnreadCount: (state, action) => {
      const { exchangeId, count } = action.payload;
      if (exchangeId && count !== undefined) {
        state.unreadCounts[exchangeId] = count;
      }
    },
    clearError: (state) => {
      state.error = null;
      state.messageError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch Messages
      .addCase(fetchMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.loading = false;
        const { exchangeId, messages } = action.payload;
        state.messages[exchangeId] = messages;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Send Message
      .addCase(sendMessage.pending, (state) => {
        state.sendingMessage = true;
        state.messageError = null;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.sendingMessage = false;
        const { exchangeId, message } = action.payload;
        if (!state.messages[exchangeId]) {
          state.messages[exchangeId] = [];
        }
        state.messages[exchangeId].push(message);
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.sendingMessage = false;
        state.messageError = action.payload;
      })

      // Mark Messages Read
      .addCase(markMessagesRead.fulfilled, (state, action) => {
        delete state.unreadCounts[action.payload];
      })

      // Fetch Unread Counts
      .addCase(fetchUnreadCounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnreadCounts.fulfilled, (state, action) => {
        state.loading = false;
        state.unreadCounts = action.payload;
        state.error = null;
      })
      .addCase(fetchUnreadCounts.rejected, (state, action) => {
        state.loading = false;
        // Don't set error state for rate limit errors
        if (action.payload === 'Rate limit exceeded') {
          return;
        }
        state.error = action.payload;
        // Set empty unread counts on error to prevent UI issues
        state.unreadCounts = {};
      });
  },
});

export const { clearMessages, clearUnreadCount, updateUnreadCount, clearError } = chatSlice.actions;
export default chatSlice.reducer;