// frontend/src/components/VerifyEmail.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { showToast } from '../utils/toast';
import { Card, CardHeader, CardContent, CardTitle } from "./ui/card";

const VerifyEmail = () => {
  const [verificationStatus, setVerificationStatus] = useState('verifying');
  const { token } = useParams(); // Get token from URL params
  const navigate = useNavigate();
  
  useEffect(() => {
    const verifyEmail = async () => {
      try {
        if (!token) {
          setVerificationStatus('error');
          return;
        }

        await api.get(`/users/verify-email/${token}`);
        setVerificationStatus('success');
        showToast.success('Email verified successfully!');
        
        // Redirect to login after 3 seconds
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } catch (error) {
        console.error('Verification error:', error);
        setVerificationStatus('error');
        showToast.error(error.response?.data?.message || 'Verification failed');
      }
    };

    verifyEmail();
  }, [token, navigate]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Card className="max-w-md mx-auto">
        <CardHeader>
          <CardTitle className="text-center">Email Verification</CardTitle>
        </CardHeader>
        <CardContent className="text-center">
          {verificationStatus === 'verifying' && (
            <div>
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto mb-4"></div>
              <p>Verifying your email...</p>
            </div>
          )}
          
          {verificationStatus === 'success' && (
            <div>
              <div className="text-green-600 text-5xl mb-4">✓</div>
              <p className="text-gray-600">Your email has been verified successfully!</p>
              <p className="text-sm text-gray-500 mt-2">Redirecting to login...</p>
            </div>
          )}
          
          {verificationStatus === 'error' && (
            <div>
              <div className="text-red-600 text-5xl mb-4">×</div>
              <p className="text-gray-600 mb-4">Verification failed. The link may be invalid or expired.</p>
              <button
                onClick={() => navigate('/login')}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Go to Login
              </button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default VerifyEmail;