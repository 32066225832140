import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUserProfile, sendFriendRequest, removeFriend, respondToRequest } from '../redux/slices/friendSlice';
import { showToast } from '../utils/toast';
import { Card, CardHeader, CardContent, CardTitle, CardDescription, CardFooter } from './ui/card';
import { 
  User, 
  Building2, 
  Gift,
  Star,
  CheckCircle2,
  UserPlus,
  UserMinus,
  Check,
  X,
  MessageCircle
} from 'lucide-react';

const PerkCard = ({ perk }) => (
  <Card className="group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-2">
      <div className="flex items-center space-x-3">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Gift className="w-6 h-6 text-blue-500" />
        </div>
        <div>
          <CardTitle className="text-lg font-semibold">{perk.title}</CardTitle>
          <CardDescription>${perk.estimatedValue}/month</CardDescription>
        </div>
      </div>
    </CardHeader>

    <CardContent>
      <p className="text-gray-600 text-sm">{perk.description}</p>
    </CardContent>
  </Card>
);

const ExchangeCard = ({ exchange }) => (
  <Card className="group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-2">
      <div className="flex items-center space-x-3">
        <div className="p-2 bg-blue-50 rounded-lg">
          <CheckCircle2 className="w-6 h-6 text-blue-500" />
        </div>
        <div>
          <CardTitle className="text-lg font-semibold">
            {exchange.bidId.bidPerkId.title}
          </CardTitle>
          <CardDescription>
            with {exchange.bidId.bidderId.name}
          </CardDescription>
        </div>
      </div>
      <span className="text-sm text-gray-500">
        {new Date(exchange.completionDate).toLocaleDateString()}
      </span>
    </CardHeader>
  </Card>
);

const StatCard = ({ label, value, icon: Icon }) => (
  <div className="text-center bg-white rounded-lg p-4 shadow-sm border">
    <div className="flex items-center justify-center mb-2">
      <div className="p-2 bg-blue-50 rounded-lg">
        <Icon className="w-6 h-6 text-blue-500" />
      </div>
    </div>
    <p className="text-2xl font-semibold text-blue-600">{value}</p>
    <p className="text-sm text-gray-600">{label}</p>
  </div>
);

const UserProfile = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { viewedProfile, loading, error } = useSelector((state) => state.friends);
  const currentUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (userId) {
      dispatch(getUserProfile(userId));
    }
  }, [userId, dispatch]);

  const handleFriendAction = async () => {
    try {
      if (!viewedProfile.friendshipStatus) {
        await showToast.promise(
          dispatch(sendFriendRequest(userId)).unwrap(),
          {
            loading: 'Sending friend request...',
            success: 'Friend request sent!',
            error: 'Failed to send request'
          }
        );
      } else if (viewedProfile.friendshipStatus === 'accepted') {
        await showToast.promise(
          dispatch(removeFriend(userId)).unwrap(),
          {
            loading: 'Removing friend...',
            success: 'Friend removed',
            error: 'Failed to remove friend'
          }
        );
      }
    } catch (error) {
      console.error('Friend action error:', error);
    }
  };

  const handleFriendRequest = async (accept) => {
    try {
      await showToast.promise(
        dispatch(respondToRequest({
          requestId: viewedProfile.friendshipId,
          accept
        })).unwrap(),
        {
          loading: accept ? 'Accepting request...' : 'Declining request...',
          success: accept ? 'Friend request accepted!' : 'Friend request declined',
          error: 'Failed to process request'
        }
      );
      dispatch(getUserProfile(userId));
    } catch (error) {
      console.error('Error handling friend request:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!viewedProfile?.user) {
    return (
      <div className="max-w-6xl mx-auto px-4 py-8">
        <Card>
          <CardContent className="text-center py-12">
            <User className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">User not found</h3>
          </CardContent>
        </Card>
      </div>
    );
  }

  const { user, stats, perks, activeExchanges } = viewedProfile;

  const getFriendshipButton = () => {
    if (currentUser._id === userId) {
      return null;
    }

    switch (viewedProfile.friendshipStatus) {
      case 'pending':
        return viewedProfile.isFriendRequestSender ? (
          <button
            className="flex items-center px-4 py-2 text-gray-600 bg-gray-100 rounded-md cursor-not-allowed"
            disabled
          >
            <MessageCircle className="w-4 h-4 mr-2" />
            Friend Request Sent
          </button>
        ) : (
          <div className="flex gap-2">
            <button
              onClick={() => handleFriendRequest(true)}
              className="flex items-center px-4 py-2 text-white bg-green-600 rounded-md hover:bg-green-700"
            >
              <Check className="w-4 h-4 mr-2" />
              Accept
            </button>
            <button
              onClick={() => handleFriendRequest(false)}
              className="flex items-center px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
            >
              <X className="w-4 h-4 mr-2" />
              Decline
            </button>
          </div>
        );
      case 'accepted':
        return (
          <button
            onClick={handleFriendAction}
            className="flex items-center px-4 py-2 text-red-600 border border-red-600 rounded-md hover:bg-red-50"
          >
            <UserMinus className="w-4 h-4 mr-2" />
            Remove Friend
          </button>
        );
      default:
        return (
          <button
            onClick={handleFriendAction}
            className="flex items-center px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
          >
            <UserPlus className="w-4 h-4 mr-2" />
            Add Friend
          </button>
        );
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <Card className="mb-8">
        <CardHeader>
          <div className="flex justify-between items-start">
            <div className="flex items-center space-x-4">
              <div className="p-2 bg-blue-50 rounded-lg">
                <User className="w-12 h-12 text-blue-500" />
              </div>
              <div>
                <CardTitle className="text-2xl">
                  {user.username}
                  {user.averageRating && (
                    <span className="ml-3 inline-flex items-center text-lg">
                      <Star className="w-5 h-5 text-yellow-400 mr-1" />
                      {user.averageRating.toFixed(1)}
                      <span className="text-gray-500 text-base ml-1">
                        ({user.totalRatings})
                      </span>
                    </span>
                  )}
                </CardTitle>
                <CardDescription className="flex items-center mt-1">
                  <Building2 className="w-4 h-4 mr-2" />
                  {user.company}
                </CardDescription>
              </div>
            </div>
            {getFriendshipButton()}
          </div>
        </CardHeader>

        <CardContent className="space-y-8">
          {/* Stats Section */}
          <div className="grid grid-cols-3 gap-4">
            <StatCard 
              label="Completed Exchanges"
              value={stats.completedExchanges}
              icon={CheckCircle2}
            />
            <StatCard 
              label="Exchanges with You"
              value={stats.completedExchangesWithUser}
              icon={UserPlus}
            />
            <StatCard 
              label="Active Exchanges"
              value={stats.activeExchanges}
              icon={Gift}
            />
          </div>

          {/* Available Perks Section */}
          {perks && perks.length > 0 && (
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Available Perks</h3>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {perks.map((perk) => (
                  <PerkCard key={perk._id} perk={perk} />
                ))}
              </div>
            </div>
          )}

          {/* Active Exchanges Section */}
          {activeExchanges && activeExchanges.length > 0 && (
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Active Exchanges</h3>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {activeExchanges.map((exchange) => (
                  <ExchangeCard key={exchange._id} exchange={exchange} />
                ))}
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default UserProfile;