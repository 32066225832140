// frontend/src/redux/slices/supportSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const createSupportTicket = createAsyncThunk(
  'support/createSupportTicket',
  async ({ exchangeId, description }, { rejectWithValue }) => {
    try {
      const response = await api.post('/support', { exchangeId, description });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to create support ticket');
    }
  }
);

const supportSlice = createSlice({
  name: 'support',
  initialState: {
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSupportTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSupportTicket.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSupportTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default supportSlice.reducer;