import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardTitle, CardDescription, CardFooter } from "./ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

const PublicMarketplace = () => {
  const [perks, setPerks] = useState([]);
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const fetchPerks = async () => {
      try {
        const response = await fetch('https://perkexchange-production.up.railway.app/api/perks');
        const data = await response.json();
        const publicPerks = data.filter(perk => 
          !perk.userId.discoverability || perk.userId.discoverability === 'public'
        );
        setPerks(publicPerks);
      } catch (error) {
        console.error('Error fetching perks:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPerks();
  }, []);

  const steps = [
    { title: "Post Your Perk", icon: "📝" },
    { title: "Receive Offers", icon: "🤝" },
    { title: "Swap & Enjoy", icon: "🎉" }
  ];

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div>
      {/* Hero Section - Reduced height */}
      <div className="bg-gradient-to-b from-yellow-50 to-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center py-12">
            <h1 className="text-5xl md:text-6xl font-black mb-8 text-[#3B5CDE]"
                style={{
                  fontFamily: 'Montserrat, sans-serif',
                  textShadow: '2px 2px 0 rgba(0,0,0,0.2), 0 0 30px rgba(59,92,222,0.2)',
                  lineHeight: '1.2',
                  padding: '0.5em 0'
                }}>
              Exchange Your<br />Employee Perks
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Trade unused company perks for rewards you'll actually use.
            </p>
            {!isAuthenticated && (
              <div className="flex justify-center gap-4">
                <Link
                  to="/login"
                  className="bg-blue-600 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-blue-700 transition-all transform hover:scale-105 duration-200 shadow-lg"
                >
                  Log In
                </Link>
                <Link
                  to="/register"
                  className="bg-gray-900 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-gray-800 transition-all transform hover:scale-105 duration-200 shadow-lg"
                >
                  Sign Up
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="max-w-7xl mx-auto px-4 py-12 border-b">
        <h2 className="text-2xl font-bold text-center mb-8">How It Works</h2>
        <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
          {steps.map((step, index) => (
            <div key={index} className="text-center">
              <div className="text-3xl mb-3">{step.icon}</div>
              <h3 className="text-lg font-semibold text-gray-900">{step.title}</h3>
            </div>
          ))}
        </div>
      </div>

      {/* Marketplace Section */}
      <div className="max-w-7xl mx-auto px-4 py-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-8">Available Perks</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {perks.map((perk) => (
            <Card key={perk._id} className="hover:shadow-lg transition-shadow duration-300">
              <CardHeader>
                <CardTitle>{perk.title}</CardTitle>
                <CardDescription className="flex justify-between items-center">
                  <span>{perk.userId.company || 'Company'}</span>
                  <span className="text-blue-600 font-semibold">${perk.estimatedValue}</span>
                </CardDescription>
              </CardHeader>
              
              <CardContent>
                <p className="text-gray-600 mb-4">{perk.description}</p>
                <div className="flex items-center gap-2 text-sm text-gray-500">
                  <span>Posted by: {perk.userId.username}</span>
                  {perk.userId.averageRating && (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <div className="flex items-center gap-1 cursor-help">
                            <span className="text-yellow-400">★</span>
                            <span>{perk.userId.averageRating.toFixed(1)}</span>
                            <span className="text-gray-500">
                              ({perk.userId.totalRatings})
                            </span>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <div className="p-2">
                            <p className="font-medium mb-1">Exchange History</p>
                            <p className="text-sm">Successfully completed {perk.userId.totalRatings} exchanges</p>
                            <p className="text-sm text-gray-400">Average rating: {perk.userId.averageRating.toFixed(1)} / 5</p>
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </div>
              </CardContent>

              <CardFooter>
                {!isAuthenticated ? (
                  <Link
                    to="/login"
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors text-center"
                  >
                    Login to Trade
                  </Link>
                ) : (
                  <Link
                    to="/marketplace"
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors text-center"
                  >
                    View in Marketplace
                  </Link>
                )}
              </CardFooter>
            </Card>
          ))}
        </div>

        {perks.length === 0 && (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <h3 className="text-lg font-medium text-gray-900 mb-2">No perks available</h3>
            <p className="text-gray-600">Check back later for new perks</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicMarketplace;