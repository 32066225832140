// src/components/ResetPassword.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../utils/toast';
import api from '../utils/api';
import { Card, CardHeader, CardContent, CardTitle, CardDescription } from "./ui/card";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [stage, setStage] = useState('email'); // email -> otp -> newPassword
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [otpRequestTime, setOtpRequestTime] = useState(null);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email || isSubmitting) return;

    setIsSubmitting(true);
    try {
      await api.post('/users/request-password-reset', { email });
      setOtpRequestTime(new Date());
      setStage('otp');
      showToast.success('If the email exists, a code has been sent.');
    } catch (error) {
      // Don't show error for security reasons
      showToast.success('If the email exists, a code has been sent.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (!otp || isSubmitting) return;

    setIsSubmitting(true);
    try {
      await api.post('/users/verify-reset-otp', { email, otp });
      setStage('newPassword');
    } catch (error) {
      showToast.error('Invalid code. Please try again.');
      setRetryCount(prev => prev + 1);
      if (retryCount >= 2) {
        setStage('email');
        setOtp('');
        setRetryCount(0);
        showToast.error('Too many attempts. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword || isSubmitting) return;

    if (newPassword !== confirmPassword) {
      showToast.error('Passwords do not match');
      return;
    }

    if (newPassword.length < 8) {
      showToast.error('Password must be at least 8 characters');
      return;
    }

    setIsSubmitting(true);
    try {
      await api.post('/users/reset-password', { 
        email, 
        otp,
        newPassword 
      });
      showToast.success('Password reset successfully');
      navigate('/login');
    } catch (error) {
      showToast.error(error.response?.data?.message || 'Failed to reset password');
    } finally {
      setIsSubmitting(false);
    }
  };

  const canResendOtp = otpRequestTime && 
    (new Date() - new Date(otpRequestTime)) > 60000; // 1 minute cooldown

  const handleResendOtp = async () => {
    if (!canResendOtp || isSubmitting) return;

    setIsSubmitting(true);
    try {
      await api.post('/users/request-password-reset', { email });
      setOtpRequestTime(new Date());
      showToast.success('New code sent');
    } catch (error) {
      // Don't show error for security
      showToast.success('If the email exists, a new code has been sent.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Card className="max-w-md mx-auto">
        <CardHeader>
          <CardTitle>Reset Password</CardTitle>
          {stage === 'email' && (
            <CardDescription>
              Enter your email address to receive a reset code
            </CardDescription>
          )}
        </CardHeader>
        <CardContent>
          {stage === 'email' && (
            <form onSubmit={handleEmailSubmit} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => navigate('/login')}
                  className="text-sm text-blue-600 hover:underline"
                >
                  Back to Login
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`px-4 py-2 text-sm font-medium text-white rounded-md ${
                    isSubmitting
                      ? 'bg-blue-400 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700'
                  }`}
                >
                  {isSubmitting ? 'Sending...' : 'Send Reset Code'}
                </button>
              </div>
            </form>
          )}

          {stage === 'otp' && (
            <form onSubmit={handleOtpSubmit} className="space-y-4">
              <div>
                <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
                  Enter Reset Code
                </label>
                <input
                  type="text"
                  id="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                  required
                  maxLength={6}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Please check your email for the reset code
                </p>
              </div>
              <div className="flex justify-between items-center">
                <button
                  type="button"
                  onClick={handleResendOtp}
                  disabled={!canResendOtp || isSubmitting}
                  className={`text-sm ${
                    canResendOtp && !isSubmitting
                      ? 'text-blue-600 hover:underline cursor-pointer'
                      : 'text-gray-400 cursor-not-allowed'
                  }`}
                >
                  Resend Code
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`px-4 py-2 text-sm font-medium text-white rounded-md ${
                    isSubmitting
                      ? 'bg-blue-400 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700'
                  }`}
                >
                  {isSubmitting ? 'Verifying...' : 'Verify Code'}
                </button>
              </div>
            </form>
          )}

          {stage === 'newPassword' && (
            <form onSubmit={handlePasswordSubmit} className="space-y-4">
              <div>
                <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                  New Password
                </label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                  required
                  minLength={8}
                />
              </div>
              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                  required
                  minLength={8}
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full px-4 py-2 text-sm font-medium text-white rounded-md ${
                  isSubmitting
                    ? 'bg-blue-400 cursor-not-allowed'
                    : 'bg-blue-600 hover:bg-blue-700'
                }`}
              >
                {isSubmitting ? 'Updating Password...' : 'Update Password'}
              </button>
            </form>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ResetPassword;