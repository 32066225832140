// frontend/src/redux/slices/friendSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const sendFriendRequest = createAsyncThunk(
  'friends/sendRequest',
  async (recipientId, { rejectWithValue }) => {
    try {
      console.log('Sending friend request to:', recipientId);
      const response = await api.post('/friends/request', { recipientId });
      console.log('Friend request response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Friend request error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        recipientId
      });
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const getFriends = createAsyncThunk(
  'friends/getFriends',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/friends');
      return response.data;
    } catch (error) {
      console.error('Get friends error:', error);
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch friends');
    }
  }
);

export const getPendingRequests = createAsyncThunk(
  'friends/getPendingRequests',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/friends/pending');
      return response.data;
    } catch (error) {
      console.error('Get pending requests error:', error);
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch pending requests');
    }
  }
);

export const respondToRequest = createAsyncThunk(
  'friends/respondToRequest',
  async ({ requestId, accept }, { rejectWithValue }) => {
    try {
      const response = await api.post('/friends/respond', { requestId, accept });
      return response.data;
    } catch (error) {
      console.error('Respond to request error:', error);
      return rejectWithValue(error.response?.data?.message || 'Failed to respond to request');
    }
  }
);

export const removeFriend = createAsyncThunk(
  'friends/removeFriend',
  async (friendId, { rejectWithValue }) => {
    try {
      await api.delete(`/friends/${friendId}`);
      return friendId;
    } catch (error) {
      console.error('Remove friend error:', error);
      return rejectWithValue(error.response?.data?.message || 'Failed to remove friend');
    }
  }
);

export const getUserProfile = createAsyncThunk(
  'friends/getUserProfile',
  async (userId, { rejectWithValue }) => {
    try {
      console.log('Fetching user profile for:', userId);
      const response = await api.get(`/users/${userId}`);
      console.log('User profile response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Get user profile error:', {
        userId,
        error: error.message,
        response: error.response?.data
      });
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch user profile');
    }
  }
);

const friendSlice = createSlice({
  name: 'friends',
  initialState: {
    friends: [],
    pendingRequests: [],
    viewedProfile: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearViewedProfile: (state) => {
      state.viewedProfile = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Send Friend Request
      .addCase(sendFriendRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendFriendRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.pendingRequests.push(action.payload);
      })
      .addCase(sendFriendRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to send friend request';
      })
      
      // Get Friends
      .addCase(getFriends.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFriends.fulfilled, (state, action) => {
        state.loading = false;
        state.friends = action.payload;
      })
      .addCase(getFriends.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Get Pending Requests
      .addCase(getPendingRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPendingRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.pendingRequests = action.payload;
      })
      .addCase(getPendingRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Respond to Request
      .addCase(respondToRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(respondToRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.pendingRequests = state.pendingRequests.filter(
          req => req._id !== action.payload._id
        );
        if (action.payload.status === 'accepted') {
          state.friends.push(action.payload);
        }
      })
      .addCase(respondToRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Remove Friend
      .addCase(removeFriend.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeFriend.fulfilled, (state, action) => {
        state.loading = false;
        state.friends = state.friends.filter(friend => {
          const friendId = friend.requesterId._id === action.payload 
            ? friend.requesterId._id 
            : friend.recipientId._id;
          return friendId !== action.payload;
        });
      })
      .addCase(removeFriend.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Get User Profile
      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.viewedProfile = action.payload;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.viewedProfile = null;
      });
  },
});

export const { clearViewedProfile, clearError } = friendSlice.actions;
export default friendSlice.reducer;