// src/components/MyPerks.js
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePerk, deletePerk, createPerk, fetchUserPerks } from '../redux/slices/perkSlice';
import { showToast } from '../utils/toast';
import { 
 Card, 
 CardHeader, 
 CardContent, 
 CardFooter, 
 CardTitle, 
 CardDescription 
} from "./ui/card";

// Separate form component with its own state management
const PerkForm = React.memo(({ isEditing, initialData, onSubmit, onCancel }) => {
 const [formData, setFormData] = useState(initialData);

 const handleChange = (e) => {
   const { name, value } = e.target;
   setFormData(prev => ({
     ...prev,
     [name]: value
   }));
 };

 const handleSubmit = (e) => {
   e.preventDefault();
   onSubmit(formData);
 };

 return (
   <form onSubmit={handleSubmit} className="space-y-4 p-6">
     <div>
       <label htmlFor={`title-${isEditing ? 'edit' : 'new'}`} className="block text-sm font-medium text-gray-700">
         Perk Title
       </label>
       <input
         id={`title-${isEditing ? 'edit' : 'new'}`}
         name="title"
         type="text"
         value={formData.title}
         onChange={handleChange}
         className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
         required
         placeholder="e.g., Monthly Gym Membership"
       />
     </div>

     <div>
       <label htmlFor={`description-${isEditing ? 'edit' : 'new'}`} className="block text-sm font-medium text-gray-700">
         Description
       </label>
       <textarea
         id={`description-${isEditing ? 'edit' : 'new'}`}
         name="description"
         value={formData.description}
         onChange={handleChange}
         className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
         rows={3}
         required
         placeholder="Describe your perk in detail..."
       />
     </div>

     <div>
       <label htmlFor={`estimatedValue-${isEditing ? 'edit' : 'new'}`} className="block text-sm font-medium text-gray-700">
         Estimated Monthly Value ($)
       </label>
       <input
         id={`estimatedValue-${isEditing ? 'edit' : 'new'}`}
         name="estimatedValue"
         type="number"
         value={formData.estimatedValue}
         onChange={handleChange}
         className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
         required
         min="0"
         step="0.01"
         placeholder="100"
       />
     </div>

     <div className="flex justify-end space-x-3">
       {onCancel && (
         <button
           type="button"
           onClick={onCancel}
           className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
         >
           Cancel
         </button>
       )}
       <button
         type="submit"
         className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
       >
         {isEditing ? 'Save Changes' : 'Create Perk'}
       </button>
     </div>
   </form>
 );
});

const MyPerks = () => {
 const dispatch = useDispatch();
 const [showCreateForm, setShowCreateForm] = useState(false);
 const [editingPerk, setEditingPerk] = useState(null);

 // Get data from Redux store
 const userPerks = useSelector((state) => state.perks.items.filter(
   perk => perk.userId._id === state.auth.user?._id
 ));
 const loading = useSelector((state) => state.perks.loading);

 useEffect(() => {
   dispatch(fetchUserPerks());
 }, [dispatch]);

 const handleCreateSubmit = useCallback(async (formData) => {
   try {
     await showToast.promise(
       dispatch(createPerk(formData)).unwrap(),
       {
         loading: 'Creating perk...',
         success: 'Perk created successfully!',
         error: 'Failed to create perk. Please try again.'
       }
     );
     setShowCreateForm(false);
   } catch (error) {
     console.error('Error creating perk:', error);
   }
 }, [dispatch]);

 const handleUpdateSubmit = useCallback(async (perkId, formData) => {
   try {
     await showToast.promise(
       dispatch(updatePerk({ perkId, updateData: formData })).unwrap(),
       {
         loading: 'Updating perk...',
         success: 'Perk updated successfully',
         error: 'Failed to update perk'
       }
     );
     setEditingPerk(null);
   } catch (error) {
     console.error('Error updating perk:', error);
   }
 }, [dispatch]);

 const handleDelete = useCallback(async (perkId) => {
   try {
     await showToast.promise(
       dispatch(deletePerk(perkId)).unwrap(),
       {
         loading: 'Deleting perk...',
         success: 'Perk deleted successfully',
         error: 'Failed to delete perk'
       }
     );
   } catch (error) {
     console.error('Error deleting perk:', error);
   }
 }, [dispatch]);

 if (loading) {
   return (
     <div className="max-w-7xl mx-auto px-4 py-8 text-center">
       Loading your perks...
     </div>
   );
 }

 return (
   <div className="max-w-7xl mx-auto px-4 py-8">
     <div className="flex justify-between items-center mb-8">
       <h2 className="text-3xl font-bold text-gray-900">My Perks</h2>
       {!showCreateForm && (
         <button
           onClick={() => setShowCreateForm(true)}
           className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
         >
           Add New Perk
         </button>
       )}
     </div>

     {showCreateForm && (
       <div className="mb-8">
         <Card>
           <PerkForm
             isEditing={false}
             initialData={{ title: '', description: '', estimatedValue: '' }}
             onSubmit={handleCreateSubmit}
             onCancel={() => setShowCreateForm(false)}
           />
         </Card>
       </div>
     )}

     {userPerks && userPerks.length > 0 ? (
       <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
         {userPerks.map((perk) => (
           <Card key={perk._id} className="hover:shadow-lg transition-shadow duration-300">
             {editingPerk === perk._id ? (
               <PerkForm
                 isEditing={true}
                 initialData={{
                   title: perk.title,
                   description: perk.description,
                   estimatedValue: perk.estimatedValue
                 }}
                 onSubmit={(formData) => handleUpdateSubmit(perk._id, formData)}
                 onCancel={() => setEditingPerk(null)}
               />
             ) : (
               <>
                 <CardHeader>
                   <CardTitle>{perk.title}</CardTitle>
                   <CardDescription className="text-blue-600 font-semibold">
                     ${perk.estimatedValue}/month
                   </CardDescription>
                 </CardHeader>
                 
                 <CardContent>
                   <p className="text-gray-600">{perk.description}</p>
                 </CardContent>

                 <CardFooter className="justify-end space-x-2">
                   <button
                     onClick={() => setEditingPerk(perk._id)}
                     className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                   >
                     Edit
                   </button>
                   <button
                     onClick={() => handleDelete(perk._id)}
                     className="px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
                   >
                     Delete
                   </button>
                 </CardFooter>
               </>
             )}
           </Card>
         ))}
       </div>
     ) : (
       <div className="text-center py-12 bg-gray-50 rounded-lg">
         <h3 className="text-lg font-medium text-gray-900 mb-2">No perks added yet</h3>
         <p className="text-gray-600 mb-4">Start by adding your first company perk to exchange</p>
         {!showCreateForm && (
           <button
             onClick={() => setShowCreateForm(true)}
             className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
           >
             Add Your First Perk
           </button>
         )}
       </div>
     )}
   </div>
 );
};

export default MyPerks;