import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardTitle, CardDescription, CardFooter } from "./ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

const PublicMarketplace = () => {
  const [perks, setPerks] = useState([]);
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const fetchPerks = async () => {
      try {
        const response = await fetch('https://perkexchange-production.up.railway.app/api/perks');
        const data = await response.json();
        const publicPerks = data.filter(perk => 
          !perk.userId.discoverability || perk.userId.discoverability === 'public'
        );
        setPerks(publicPerks);
      } catch (error) {
        console.error('Error fetching perks:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPerks();
  }, []);

  const steps = [
    { 
      title: "1. Post Your Perk",
      description: "List your perks in seconds",
      icon: (
        <svg className="w-12 h-12 mx-auto text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
      )
    },
    { 
      title: "2. Receive Offers",
      description: "Connect with interested traders",
      icon: (
        <svg className="w-12 h-12 mx-auto text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
        </svg>
      )
    },
    { 
      title: "3. Swap & Enjoy",
      description: "Exchange perks seamlessly",
      icon: (
        <svg className="w-12 h-12 mx-auto text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
        </svg>
      )
    }
  ];

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div>
      {/* Hero Section */}
      <div className="relative bg-gradient-to-b from-blue-50 via-white to-white overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-100/20 to-white/20"></div>
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_left,rgba(59,130,246,0.1),transparent_600px)]"></div>
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_right,rgba(59,130,246,0.1),transparent_600px)]"></div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-b from-transparent to-white"></div>
        
        <div className="relative max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">
          <div className="py-24 md:py-32 text-left max-w-4xl">
            <div className="relative">
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-100/50 to-transparent rounded-lg blur-2xl"></div>
              <h1 className="relative text-5xl md:text-7xl font-semibold mb-8 bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent"
                  style={{
                    fontFamily: 'Montserrat, sans-serif',
                    lineHeight: '1.1',
                  }}>
                Exchange Your<br />Employee Perks
              </h1>
            </div>
            <p className="text-xl md:text-2xl text-gray-600 mb-12 max-w-3xl font-medium">
              Trade unused company perks for rewards you'll actually use.
            </p>
            {!isAuthenticated && (
              <div className="flex flex-col sm:flex-row gap-4 sm:items-center">
                <Link
                  to="/register"
                  className="inline-flex justify-center items-center px-8 py-4 text-lg font-semibold text-white bg-gradient-to-r from-blue-600 to-blue-500 rounded-lg transform transition-all hover:scale-105 hover:shadow-lg"
                >
                  Get Started
                </Link>
                <Link
                  to="/login"
                  className="inline-flex justify-center items-center px-8 py-4 text-lg font-semibold text-blue-600 bg-white border-2 border-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
                >
                  Log In
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="max-w-7xl mx-auto px-4 py-24">
        <h2 className="text-3xl font-semibold text-center mb-20 text-gray-900">How It Works</h2>
        <div className="grid md:grid-cols-3 gap-0 max-w-5xl mx-auto relative">
          <div className="absolute top-24 left-[33%] hidden md:block w-[30%] h-0.5 bg-gradient-to-r from-transparent via-blue-200 to-transparent"></div>
          <div className="absolute top-24 right-[33%] hidden md:block w-[30%] h-0.5 bg-gradient-to-r from-transparent via-blue-200 to-transparent"></div>
          {steps.map((step, index) => (
            <div key={index} className="text-center px-8">
              <div className="mb-8 transition-transform transform hover:scale-110">
                <div className="w-20 h-20 mx-auto">
                  {step.icon}
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Available Perks Section */}
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-semibold text-gray-900 mb-12">Available Perks</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {perks.map((perk) => (
              <Card key={perk._id} className="hover:shadow-xl transition-all duration-300 bg-white flex flex-col h-full">
                <CardHeader>
                  <CardTitle className="text-xl">{perk.title}</CardTitle>
                  <CardDescription className="flex justify-between items-center">
                    <span className="text-gray-600">{perk.userId.company || 'Company'}</span>
                    <span className="text-xl font-bold text-blue-600">${perk.estimatedValue}</span>
                  </CardDescription>
                </CardHeader>
                
                <CardContent className="flex-grow">
                  <p className="text-gray-600 mb-4">{perk.description}</p>
                  <div className="flex items-center gap-2 text-sm text-gray-500">
                    <span className="font-medium">Posted by: {perk.userId.username}</span>
                    {perk.userId.averageRating && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <div className="flex items-center gap-1 cursor-help">
                              <span className="text-yellow-400">★</span>
                              <span>{perk.userId.averageRating.toFixed(1)}</span>
                              <span>({perk.userId.totalRatings})</span>
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="p-2">
                              <p className="font-medium mb-1">Exchange History</p>
                              <p className="text-sm">Successfully completed {perk.userId.totalRatings} exchanges</p>
                              <p className="text-sm text-gray-400">Average rating: {perk.userId.averageRating.toFixed(1)} / 5</p>
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                </CardContent>

                <CardFooter className="mt-auto">
                  {!isAuthenticated ? (
                    <Link
                      to="/register"
                      className="w-full bg-gradient-to-r from-blue-600 to-blue-500 text-white py-3 px-4 rounded-lg text-center font-medium hover:shadow-lg transition-all hover:scale-105"
                    >
                      Sign Up to Trade
                    </Link>
                  ) : (
                    <Link
                      to="/marketplace"
                      className="w-full bg-gradient-to-r from-blue-600 to-blue-500 text-white py-3 px-4 rounded-lg text-center font-medium hover:shadow-lg transition-all hover:scale-105"
                    >
                      View in Marketplace
                    </Link>
                  )}
                </CardFooter>
              </Card>
            ))}
          </div>

          {perks.length === 0 && (
            <div className="text-center py-20 bg-white rounded-lg shadow-sm">
              <h3 className="text-xl font-semibold text-gray-900 mb-3">No perks available</h3>
              <p className="text-gray-600">Check back later for new perks</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicMarketplace;