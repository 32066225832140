import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUserProfile, sendFriendRequest, removeFriend, respondToRequest } from '../redux/slices/friendSlice';
import { showToast } from '../utils/toast';
import { Card, CardHeader, CardContent, CardFooter, CardTitle } from './ui/card';

const UserProfile = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { viewedProfile, loading, error } = useSelector((state) => state.friends);
  const currentUser = useSelector((state) => state.auth.user);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (userId) {
      console.log('Fetching profile for userId:', userId);
      dispatch(getUserProfile(userId));
    }
  }, [userId, dispatch]);

  const handleFriendAction = async () => {
    try {
      setIsProcessing(true);
      if (!viewedProfile.friendshipStatus) {
        console.log('Sending friend request to:', userId);
        await showToast.promise(
          dispatch(sendFriendRequest(userId)).unwrap(),
          {
            loading: 'Sending friend request...',
            success: 'Friend request sent!',
            error: (err) => `Failed to send request: ${err.message || 'Unknown error'}`
          }
        );
      } else if (viewedProfile.friendshipStatus === 'accepted') {
        console.log('Removing friend:', userId);
        await showToast.promise(
          dispatch(removeFriend(userId)).unwrap(),
          {
            loading: 'Removing friend...',
            success: 'Friend removed',
            error: (err) => `Failed to remove friend: ${err.message || 'Unknown error'}`
          }
        );
      }
    } catch (error) {
      console.error('Friend action error:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFriendRequest = async (accept) => {
    try {
      setIsProcessing(true);
      await showToast.promise(
        dispatch(respondToRequest({
          requestId: viewedProfile.friendshipId,
          accept
        })).unwrap(),
        {
          loading: accept ? 'Accepting request...' : 'Declining request...',
          success: accept ? 'Friend request accepted!' : 'Friend request declined',
          error: 'Failed to process request'
        }
      );
      // Refresh the profile after responding to request
      dispatch(getUserProfile(userId));
    } catch (error) {
      console.error('Error handling friend request:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12 text-red-600">
        <p>Error: {error}</p>
      </div>
    );
  }

  if (!viewedProfile?.user) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-600">User not found</p>
      </div>
    );
  }

  const getFriendshipButton = () => {
    if (currentUser._id === userId) {
      return null;
    }

    if (isProcessing) {
      return (
        <button
          className="w-full px-4 py-2 bg-gray-300 text-gray-600 rounded-md cursor-not-allowed"
          disabled
        >
          Processing...
        </button>
      );
    }

    switch (viewedProfile.friendshipStatus) {
      case 'pending':
        return viewedProfile.isFriendRequestSender ? (
          <button
            className="w-full px-4 py-2 text-gray-600 bg-gray-100 rounded-md"
            disabled
          >
            Friend Request Sent
          </button>
        ) : (
          <div className="flex gap-2">
            <button
              onClick={() => handleFriendRequest(true)}
              className="flex-1 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
            >
              Accept
            </button>
            <button
              onClick={() => handleFriendRequest(false)}
              className="flex-1 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
            >
              Decline
            </button>
          </div>
        );
      case 'accepted':
        return (
          <button
            onClick={handleFriendAction}
            className="w-full px-4 py-2 text-red-600 border border-red-600 rounded-md hover:bg-red-50"
          >
            Remove Friend
          </button>
        );
      default:
        return (
          <button
            onClick={handleFriendAction}
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Add Friend
          </button>
        );
    }
  };

  const { user, stats, perks, activeExchanges } = viewedProfile;

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <div className="flex items-start justify-between">
            <div>
              <CardTitle className="text-2xl">{user.username}</CardTitle>
              <p className="text-gray-600">{user.company}</p>
            </div>
            <div className="text-right">
              {user.averageRating && (
                <div className="flex items-center gap-1">
                  <span className="text-yellow-400">★</span>
                  <span className="text-gray-900">{user.averageRating.toFixed(1)}</span>
                  <span className="text-gray-500">({user.totalRatings})</span>
                </div>
              )}
            </div>
          </div>
        </CardHeader>

        <CardContent className="space-y-6">
          {/* Stats Section */}
          <div className="grid grid-cols-3 gap-4 bg-gray-50 rounded-lg p-4">
            <div className="text-center">
              <p className="text-2xl font-semibold text-blue-600">
                {stats?.completedExchanges || 0}
              </p>
              <p className="text-sm text-gray-600">Completed Exchanges</p>
            </div>
            <div className="text-center">
              <p className="text-2xl font-semibold text-blue-600">
                {stats?.completedExchangesWithUser || 0}
              </p>
              <p className="text-sm text-gray-600">Exchanges with You</p>
            </div>
            <div className="text-center">
              <p className="text-2xl font-semibold text-blue-600">
                {stats?.activeExchanges || 0}
              </p>
              <p className="text-sm text-gray-600">Active Exchanges</p>
            </div>
          </div>

          {/* Perks Section */}
          {perks && perks.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-4">Available Perks</h3>
              <div className="grid md:grid-cols-2 gap-4">
                {perks.map((perk) => (
                  <div
                    key={perk._id}
                    className="p-4 border rounded-lg bg-gray-50"
                  >
                    <h4 className="font-medium">{perk.title}</h4>
                    <p className="text-sm text-gray-600 mt-1">{perk.description}</p>
                    <p className="text-blue-600 font-medium mt-2">
                      ${perk.estimatedValue}/month
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Active Exchanges Section */}
          {activeExchanges && activeExchanges.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold mb-4">Active Exchanges</h3>
              <div className="space-y-4">
                {activeExchanges.map((exchange) => (
                  <div
                    key={exchange._id}
                    className="p-4 border rounded-lg"
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <p className="font-medium">
                          {exchange.bidId.bidPerkId.title}
                        </p>
                        <p className="text-sm text-gray-600">
                          with {exchange.bidId.bidderId.name}
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="text-sm text-gray-600">
                          Completion: {new Date(exchange.completionDate).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </CardContent>

        <CardFooter>
          {getFriendshipButton()}
        </CardFooter>
      </Card>
    </div>
  );
};

export default UserProfile;