import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFriends } from '../redux/slices/friendSlice';
import { Link, useNavigate } from 'react-router-dom';
import { showToast } from '../utils/toast';
import { fetchPerks } from '../redux/slices/perkSlice';
import { createBid } from '../redux/slices/bidSlice';
import { 
 Card, 
 CardHeader, 
 CardContent, 
 CardFooter, 
 CardTitle, 
 CardDescription 
} from "./ui/card";
import {
 Tooltip,
 TooltipContent,
 TooltipProvider,
 TooltipTrigger,
} from "./ui/tooltip";
import { 
 Gift, 
 TrendingUp, 
 Clock, 
 Star, 
 Zap, 
 Flame, 
 Tag 
} from 'lucide-react';

const MOCK_TRADES = [
 { id: 1, text: "🚀 Alex traded $50 Starbucks for ClassPass membership" },
 { id: 2, text: "🔥 Jenny accepted an offer for Nike shoes" },
 { id: 3, text: "💫 Mark exchanged DoorDash credits for Spotify Premium" }
];

const FilterPill = ({ active, children, onClick }) => (
 <button
   onClick={onClick}
   className={`px-4 py-2 rounded-full text-sm font-medium transition-all ${
     active 
       ? 'bg-blue-600 text-white shadow-md'
       : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
   }`}
 >
   {children}
 </button>
);

const LiveTicker = () => {
 const [currentIndex, setCurrentIndex] = useState(0);

 useEffect(() => {
   const timer = setInterval(() => {
     setCurrentIndex((prev) => (prev + 1) % MOCK_TRADES.length);
   }, 5000);
   return () => clearInterval(timer);
 }, []);

 return (
   <div className="bg-gradient-to-r from-blue-50 to-white p-3 rounded-lg mb-6 overflow-hidden">
     <div className="flex items-center text-sm text-gray-600 animate-slide">
       <Zap className="w-4 h-4 text-blue-500 mr-2" />
       {MOCK_TRADES[currentIndex].text}
     </div>
   </div>
 );
};

const PerkCard = ({ perk, existingBid, userPerks, onBid }) => {
 const [showDetails, setShowDetails] = useState(false);
 const isNew = new Date(perk.createdAt) > new Date(Date.now() - 86400000);
 const isPopular = perk.estimatedValue > 100;
 
 const RatingDisplay = () => {
   if (!perk.userId.averageRating) return null;
   
   return (
     <TooltipProvider>
       <Tooltip>
         <TooltipTrigger asChild>
           <div className="flex items-center gap-1 cursor-help">
             <Star className="w-4 h-4 text-yellow-400" />
             <span>{perk.userId.averageRating.toFixed(1)}</span>
             <span className="text-gray-500">({perk.userId.totalRatings})</span>
           </div>
         </TooltipTrigger>
         <TooltipContent>
           <p>Average rating based on {perk.userId.totalRatings} exchanges</p>
         </TooltipContent>
       </Tooltip>
     </TooltipProvider>
   );
 };

 return (
   <Card className="group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 cursor-pointer">
     <div onClick={() => setShowDetails(!showDetails)}>
       <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-2">
         <div className="flex items-center space-x-3">
           <div className="p-2 bg-blue-50 rounded-lg">
             <Gift className="w-6 h-6 text-blue-500" />
           </div>
           <div>
             <CardTitle className="text-lg font-semibold">{perk.title}</CardTitle>
             <CardDescription>
               <div className="flex items-center gap-2">
                 <span>{perk.userId.company || 'Company'}</span>
                 <RatingDisplay />
               </div>
             </CardDescription>
           </div>
         </div>
         <div className="text-xl font-bold text-blue-600">
           ${perk.estimatedValue}
         </div>
       </CardHeader>
       
       <CardContent>
         <div className="flex gap-2 mb-3">
           {isNew && (
             <span className="px-2 py-1 bg-green-100 text-green-700 text-xs font-medium rounded-full">
               New
             </span>
           )}
           {isPopular && (
             <span className="px-2 py-1 bg-orange-100 text-orange-700 text-xs font-medium rounded-full">
               Popular
             </span>
           )}
         </div>
         <p className="text-gray-600 text-sm">{perk.description}</p>
       </CardContent>
     </div>

     <CardFooter className="flex flex-col space-y-3">
       {showDetails && (
         <div className="w-full">
           {existingBid ? (
             <div className="w-full p-3 bg-gray-50 rounded-lg text-gray-600 text-center">
               Bid Already Placed
             </div>
           ) : (
             <BidForm 
               perk={perk} 
               userPerks={userPerks}
               onBid={onBid}
             />
           )}
         </div>
       )}
     </CardFooter>
   </Card>
 );
};

const BidForm = ({ perk, userPerks, onBid }) => {
 const [selectedPerk, setSelectedPerk] = useState('');
 const [quantity, setQuantity] = useState(1);

 useEffect(() => {
   if (userPerks.length === 1) {
     setSelectedPerk(userPerks[0]._id);
   }
 }, [userPerks]);

 const handleSubmit = (e) => {
   e.preventDefault();
   onBid(perk._id, selectedPerk, quantity);
 };

 return (
   <form onSubmit={handleSubmit} className="space-y-3">
     {userPerks.length === 1 ? (
       <div className="p-2 bg-blue-50 rounded-lg text-sm text-blue-700">
         Trading with: {userPerks[0].title}
       </div>
     ) : (
       <select
         value={selectedPerk}
         onChange={(e) => setSelectedPerk(e.target.value)}
         className="w-full p-2 border rounded-lg"
       >
         <option value="">Select your perk to offer</option>
         {userPerks.map((userPerk) => (
           <option key={userPerk._id} value={userPerk._id}>
             {userPerk.title} (${userPerk.estimatedValue})
           </option>
         ))}
       </select>
     )}
     
     {selectedPerk && (
       <input
         type="number"
         min="1"
         value={quantity}
         onChange={(e) => setQuantity(parseInt(e.target.value))}
         className="w-full p-2 border rounded-lg"
         placeholder="Quantity"
       />
     )}
     
     <button
       type="submit"
       disabled={!selectedPerk}
       className="w-full bg-gradient-to-r from-blue-600 to-blue-500 text-white py-2 px-4 rounded-lg hover:from-blue-700 hover:to-blue-600 transition-colors disabled:from-gray-400 disabled:to-gray-300 disabled:cursor-not-allowed transform hover:scale-[1.02] active:scale-[0.98] duration-200"
     >
       {userPerks.length === 1 ? 'Quick Bid' : 'Place Bid'}
     </button>
   </form>
 );
};

const Marketplace = () => {
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const [sortBy, setSortBy] = useState('recent');

 const perks = useSelector((state) => state.perks.items);
 const userPerks = useSelector((state) => 
   state.perks.items.filter(perk => perk.userId._id === state.auth.user?._id) || []
 );
 const bids = useSelector((state) => state.bids.items);
 const currentUserId = useSelector((state) => state.auth.user?._id);
 const friends = useSelector((state) => state.friends.friends);

 useEffect(() => {
   dispatch(fetchPerks());
   dispatch(getFriends());
 }, [dispatch]);

 const hasExistingBid = (marketPerkId) => {
   return bids.some(bid => 
     bid.offeredPerkId === marketPerkId && 
     bid.status === 'pending'
   );
 };

 const displayPerks = useMemo(() => {
   const filteredPerks = perks.filter(perk => {
     const isOwnPerk = perk.userId._id === currentUserId;
     const isPublic = !perk.userId.discoverability || perk.userId.discoverability === 'public';
     const isFriend = friends.some(friend => 
       friend.requesterId._id === perk.userId._id || 
       friend.recipientId._id === perk.userId._id
     );
     return !isOwnPerk && (isPublic || isFriend);
   });

   return [...filteredPerks].sort((a, b) => {
     switch (sortBy) {
       case 'value-high':
         return b.estimatedValue - a.estimatedValue;
       case 'value-low':
         return a.estimatedValue - b.estimatedValue;
       case 'company':
         return (a.userId.company || '').localeCompare(b.userId.company || '');
       case 'recent':
         return new Date(b.createdAt) - new Date(a.createdAt);
       default:
         return 0;
     }
   });
 }, [perks, currentUserId, friends, sortBy]);

 const handleBid = async (marketPerkId, userPerkId, quantity) => {
   try {
     await showToast.promise(
       dispatch(createBid({
         offeredPerkId: marketPerkId,
         bidPerkId: userPerkId,
         quantity
       })).unwrap(),
       {
         loading: 'Placing your bid...',
         success: 'Bid placed successfully!',
         error: 'Failed to place bid. Please try again.'
       }
     );
   } catch (error) {
     console.error('Error in handleBid:', error);
   }
 };

 if (!perks.length) {
   return (
     <div className="max-w-7xl mx-auto px-4 py-8 text-center">
       <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
     </div>
   );
 }

 return (
   <div className="max-w-6xl mx-auto px-4 py-8">
     <LiveTicker />
     
     <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 gap-4">
       <h2 className="text-3xl font-bold text-gray-900">Available Perks</h2>
       <div className="flex flex-wrap gap-2">
         <FilterPill 
           active={sortBy === 'recent'} 
           onClick={() => setSortBy('recent')}
         >
           <Clock className="w-4 h-4 inline-block mr-1" />
           Newest
         </FilterPill>
         <FilterPill 
           active={sortBy === 'value-high'} 
           onClick={() => setSortBy('value-high')}
         >
           <TrendingUp className="w-4 h-4 inline-block mr-1" />
           High Value
         </FilterPill>
         <FilterPill 
           active={sortBy === 'popular'} 
           onClick={() => setSortBy('popular')}
         >
           <Flame className="w-4 h-4 inline-block mr-1" />
           Popular
         </FilterPill>
       </div>
     </div>

     <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
       {displayPerks.map((perk) => (
         <PerkCard
           key={perk._id}
           perk={perk}
           existingBid={hasExistingBid(perk._id)}
           userPerks={userPerks}
           onBid={handleBid}
         />
       ))}
     </div>
   </div>
 );
};

export default Marketplace;