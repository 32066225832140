// src/components/Navbar.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { fetchUnreadCounts } from '../redux/slices/chatSlice';
import { logout } from '../redux/slices/authSlice';

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const unreadCounts = useSelector((state) => state.chat.unreadCounts);
  const pendingRequests = useSelector((state) => state.friends.pendingRequests);
  const totalUnreadCount = Object.values(unreadCounts).reduce((a, b) => a + b, 0);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUnreadCounts());
      const pollInterval = setInterval(() => {
        dispatch(fetchUnreadCounts());
      }, 30000);

      return () => clearInterval(pollInterval);
    }
  }, [dispatch, isAuthenticated]);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <nav className="bg-white shadow-lg border-b">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo/Brand */}
          <div className="flex-shrink-0 flex items-center">
            <Link 
              to="/" 
              className="text-xl font-bold italic text-white px-4 py-2 bg-gradient-to-r from-blue-900 to-blue-600 rounded-lg border-2 border-white shadow-sm"
            >
              PerkExchange
            </Link>
          </div>

          {/* Navigation Links */}
          {isAuthenticated ? (
            <>
              <div className="hidden md:flex items-center space-x-6">
                <Link
                  to="/marketplace"
                  className={`px-3 py-2 text-sm font-medium transition-colors ${
                    location.pathname === '/marketplace'
                      ? 'text-blue-600'
                      : 'text-gray-700 hover:text-blue-600'
                  }`}
                >
                  Marketplace
                </Link>
                <Link
                  to="/myperks"
                  className={`px-3 py-2 text-sm font-medium transition-colors ${
                    location.pathname === '/myperks'
                      ? 'text-blue-600'
                      : 'text-gray-700 hover:text-blue-600'
                  }`}
                >
                  My Perks
                </Link>
                <Link
                  to="/mybids"
                  className={`px-3 py-2 text-sm font-medium transition-colors ${
                    location.pathname === '/mybids'
                      ? 'text-blue-600'
                      : 'text-gray-700 hover:text-blue-600'
                  }`}
                >
                  My Bids
                </Link>
                <div className="relative">
                  <Link
                    to="/acceptedbids"
                    className={`px-3 py-2 text-sm font-medium transition-colors ${
                      location.pathname === '/acceptedbids'
                        ? 'text-blue-600'
                        : 'text-gray-700 hover:text-blue-600'
                    }`}
                  >
                    Accepted Bids
                    {totalUnreadCount > 0 && (
                      <span className="absolute -top-1 -right-1 flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-600 rounded-full">
                        {totalUnreadCount > 99 ? '99+' : totalUnreadCount}
                      </span>
                    )}
                  </Link>
                </div>
                <div className="relative">
                  <Link
                    to="/friends"
                    className={`px-3 py-2 text-sm font-medium transition-colors ${
                      location.pathname === '/friends'
                        ? 'text-blue-600'
                        : 'text-gray-700 hover:text-blue-600'
                    }`}
                  >
                    Friends
                    {pendingRequests?.length > 0 && (
                      <span className="absolute -top-1 -right-1 flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-600 rounded-full">
                        {pendingRequests.length}
                      </span>
                    )}
                  </Link>
                </div>
              </div>

              {/* Right side - Profile & Logout */}
              <div className="flex items-center space-x-4">
                <Link
                  to="/profile"
                  className={`px-3 py-2 text-sm font-medium transition-colors ${
                    location.pathname === '/profile'
                      ? 'text-blue-600'
                      : 'text-gray-700 hover:text-blue-600'
                  }`}
                >
                  Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors"
                >
                  Logout
                </button>
              </div>
            </>
          ) : (
            /* Auth Buttons when not logged in */
            <div className="flex items-center space-x-4">
              <Link
                to="/login"
                className="px-4 py-2 text-sm font-medium text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Sign Up
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Mobile menu - Only show when authenticated */}
      {isAuthenticated && (
        <div className="md:hidden border-t">
          <div className="grid grid-cols-5 gap-1 p-2">
            <Link
              to="/marketplace"
              className={`p-2 text-xs font-medium text-center rounded transition-colors ${
                location.pathname === '/marketplace'
                  ? 'text-blue-600 bg-blue-50'
                  : 'text-gray-700 hover:text-blue-600'
              }`}
            >
              Marketplace
            </Link>
            <Link
              to="/myperks"
              className={`p-2 text-xs font-medium text-center rounded transition-colors ${
                location.pathname === '/myperks'
                  ? 'text-blue-600 bg-blue-50'
                  : 'text-gray-700 hover:text-blue-600'
              }`}
            >
              My Perks
            </Link>
            <Link
              to="/mybids"
              className={`p-2 text-xs font-medium text-center rounded transition-colors ${
                location.pathname === '/mybids'
                  ? 'text-blue-600 bg-blue-50'
                  : 'text-gray-700 hover:text-blue-600'
              }`}
            >
              My Bids
            </Link>
            <div className="relative">
              <Link
                to="/acceptedbids"
                className={`p-2 text-xs font-medium text-center rounded transition-colors ${
                  location.pathname === '/acceptedbids'
                    ? 'text-blue-600 bg-blue-50'
                    : 'text-gray-700 hover:text-blue-600'
                }`}
              >
                Accepted
                {totalUnreadCount > 0 && (
                  <span className="absolute -top-1 -right-1 flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-600 rounded-full">
                    {totalUnreadCount > 99 ? '99+' : totalUnreadCount}
                  </span>
                )}
              </Link>
            </div>
            <div className="relative">
              <Link
                to="/friends"
                className={`p-2 text-xs font-medium text-center rounded transition-colors ${
                  location.pathname === '/friends'
                    ? 'text-blue-600 bg-blue-50'
                    : 'text-gray-700 hover:text-blue-600'
                }`}
              >
                Friends
                {pendingRequests?.length > 0 && (
                  <span className="absolute -top-1 -right-1 flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-600 rounded-full">
                    {pendingRequests.length}
                  </span>
                )}
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;