import React, { useState } from 'react';

const SupportModal = ({ isOpen, onClose, onSubmit, exchange, currentUser }) => {
  const [description, setDescription] = useState('');

  if (!isOpen || !exchange) return null;

  // Get exchange details
  const isUserBidder = exchange.bidId.bidderId._id === currentUser._id;
  const otherUser = isUserBidder 
    ? exchange.bidId.offeredPerkId.userId 
    : exchange.bidId.bidderId;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ exchangeId: exchange._id, description });
    setDescription('');
  };

  const exchangeDetails = `
- Exchange with: ${otherUser.name} (${otherUser.company})
- Your part: ${exchange.bidId.quantity}x ${exchange.bidId.bidPerkId.title} ($${exchange.bidId.bidPerkId.estimatedValue})
- Their part: ${exchange.bidId.offeredPerkId.title} ($${exchange.bidId.offeredPerkId.estimatedValue})
- Completion Date: ${new Date(exchange.completionDate).toLocaleDateString()}
- Current Status: ${exchange.status}
- Logistics: ${exchange.logistics}
  `.trim();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-2xl w-full p-6">
        <div className="border-b pb-4 mb-4">
          <h2 className="text-xl font-bold">Request Support</h2>
          <p className="text-sm text-gray-500 mt-1">
            Support team will respond to: {currentUser.email}
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <div className="bg-gray-50 p-4 rounded-md mb-6">
              <h3 className="font-medium mb-2 text-gray-700">Exchange Details</h3>
              <pre className="whitespace-pre-wrap text-sm text-gray-600">{exchangeDetails}</pre>
            </div>

            <label className="block font-medium mb-2">Describe Your Issue</label>
            <div className="mb-4">
              <p className="text-sm text-gray-600 mb-2">Please include:</p>
              <ul className="text-sm text-gray-600 list-disc ml-4 mb-4">
                <li>What specific issue are you experiencing?</li>
                <li>When did this issue start?</li>
                <li>What steps have you already taken?</li>
                <li>What assistance do you need from our team?</li>
              </ul>
            </div>
            
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
              rows={6}
              required
              placeholder="Please provide as much detail as possible..."
            />
          </div>

          <div className="flex justify-end gap-3 pt-4 border-t">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              Submit Request
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SupportModal;