import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const fetchExchanges = createAsyncThunk(
  'exchanges/fetchExchanges',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/exchanges');
      console.log('Fetched exchanges:', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch exchanges');
    }
  }
);

export const createExchange = createAsyncThunk(
  'exchanges/createExchange',
  async (exchangeData, { rejectWithValue }) => {
    try {
      const response = await api.post('/exchanges', exchangeData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create exchange');
    }
  }
);

export const updateExchange = createAsyncThunk(
  'exchanges/updateExchange',
  async ({ id, ...updateData }, { rejectWithValue }) => {
    try {
      console.log('Sending rating update:', { id, updateData });
      const response = await api.post(`/exchanges/${id}/rate`, updateData);
      console.log('Rating update response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Rating update error:', error.response?.data);
      return rejectWithValue(error.response?.data?.message || 'Failed to update exchange');
    }
  }
);

export const initiateCompletion = createAsyncThunk(
  'exchanges/initiateCompletion',
  async (exchangeId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/exchanges/${exchangeId}/initiate-completion`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to initiate completion');
    }
  }
);

export const confirmCompletion = createAsyncThunk(
  'exchanges/confirmCompletion',
  async (exchangeId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/exchanges/${exchangeId}/confirm-completion`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to confirm completion');
    }
  }
);

const exchangeSlice = createSlice({
  name: 'exchanges',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {
    addExchange: (state, action) => {
      console.log('Adding new exchange:', action.payload);
      const exists = state.items.some(item => item._id === action.payload._id);
      if (!exists) {
        state.items.push(action.payload);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchanges.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExchanges.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchExchanges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createExchange.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(updateExchange.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExchange.fulfilled, (state, action) => {
        const index = state.items.findIndex(item => item._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateExchange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(initiateCompletion.fulfilled, (state, action) => {
        const index = state.items.findIndex(item => item._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(confirmCompletion.fulfilled, (state, action) => {
        const index = state.items.findIndex(item => item._id === action.payload._id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      });
  },
});

export const { addExchange } = exchangeSlice.actions;
export default exchangeSlice.reducer;