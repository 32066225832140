import React, { useState, useEffect } from 'react';

const TermsModal = ({ isOpen, onClose, onAccept, fullName }) => {
  const [nameConfirmation, setNameConfirmation] = useState('');
  const [hasRead, setHasRead] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setNameConfirmation('');
      setHasRead(false);
      setScrolledToBottom(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight * 1.2) {
      setScrolledToBottom(true);
    }
  };

  const canAccept = nameConfirmation.trim().toLowerCase() === fullName.trim().toLowerCase() && 
                    hasRead && 
                    scrolledToBottom;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] flex flex-col">
        <div className="p-6 border-b">
          <h2 className="text-2xl font-semibold text-gray-900">Terms and Conditions for Perk Exchange</h2>
        </div>

        <div className="p-6 overflow-y-auto flex-grow" onScroll={handleScroll}>
          <div className="prose prose-blue max-w-none space-y-6">
            <p className="text-sm text-gray-600">Effective Date: December 24, 2024</p>
            
            <p className="text-gray-800">Welcome to Perk Exchange! These Terms and Conditions ("Terms") govern your use of the Perk Exchange platform (the "Platform"), which facilitates peer-to-peer exchanges of employee perks and benefits. By signing up for or using the Platform, you agree to these Terms. Please read them carefully.</p>
            <p className="mt-4 mb-6 text-gray-800">
  Perk Exchange is a subsidiary of Conglomerated Enterprises LLC. All references to "Perk Exchange," "we," "us," or "our" in these Terms refer to Perk Exchange and its parent company, Conglomerated Enterprises LLC.
</p>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">1. Acceptance of Terms</h3>
              <p>By creating an account or using the Platform, you confirm that:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>You are at least 18 years old.</li>
                <li>You have read, understood, and agreed to these Terms.</li>
                <li>You have the legal authority to enter into these Terms and use the Platform.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">2. Nature of the Platform</h3>
              <p>Perk Exchange provides a framework for users to negotiate and conduct exchanges of employee perks and benefits. We do not guarantee the validity, legality, or enforceability of any exchange, nor are we responsible for the quality, authenticity, or value of the perks exchanged. You assume full responsibility for the transactions you engage in through the Platform.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">3. Compliance with Employer Policies</h3>
              <p>You acknowledge and agree that:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>It is your sole responsibility to ensure that your participation in any exchange complies with your employer's policies, codes of conduct, and agreements.</li>
                <li>Perk Exchange is not responsible for verifying your compliance with your employer's policies.</li>
                <li>You indemnify and hold Perk Exchange harmless for any consequences, including disciplinary action or termination, that may arise from your use of the Platform.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">4. Tax and Legal Compliance</h3>
              <p>You are solely responsible for understanding and complying with any tax obligations or legal requirements that may arise from your participation in exchanges facilitated by the Platform.</p>
              <p>Perk Exchange does not provide tax advice or documentation related to transactions on the Platform. We encourage you to consult with a tax professional regarding any potential liabilities.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">5. Assumption of Risk</h3>
              <p>By using the Platform, you assume all risks associated with:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>The negotiation and execution of exchanges.</li>
                <li>Any disputes or disagreements with other users.</li>
                <li>The potential loss, misuse, or misrepresentation of perks.</li>
                <li>Violations of applicable laws, regulations, or employer policies in your jurisdiction.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">6. Prohibited Conduct</h3>
              <p>You agree not to:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>Use the Platform to exchange items or perks that are illegal or violate third-party rights.</li>
                <li>Engage in fraudulent, deceptive, or unethical behavior.</li>
                <li>Use the Platform for commercial purposes or unauthorized resale of perks.</li>
                <li>Post or share false, misleading, or harmful content.</li>
              </ul>
              <p>Perk Exchange reserves the right to terminate accounts and remove content that violates these Terms.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">7. No Liability for Transactions</h3>
              <p>Perk Exchange serves solely as an intermediary. We are not a party to any transactions between users and do not:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>Verify the value or authenticity of perks exchanged.</li>
                <li>Guarantee the satisfaction of either party in a transaction.</li>
                <li>Provide insurance, refunds, or compensation for failed or unsatisfactory exchanges.</li>
              </ul>
              <p>All transactions are conducted at your own risk.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">8. User Responsibilities</h3>
              <p>You are solely responsible for:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>Ensuring the legality of your exchanges under applicable laws.</li>
                <li>Verifying the authenticity and condition of the perks you receive.</li>
                <li>Resolving disputes directly with other users.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">9. Privacy and Data Security</h3>
              <p>Perk Exchange takes the security of user data seriously. Your personal information will be handled in accordance with our Privacy Policy.</p>
              <p>Users are responsible for protecting their account credentials and ensuring their exchanges do not involve sharing sensitive or proprietary information.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">10. Disclaimer of Warranties</h3>
              <p>The Platform is provided on an "AS IS" and "AS AVAILABLE" basis without warranties of any kind, express or implied, including but not limited to:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>The suitability, reliability, or availability of the Platform.</li>
                <li>The accuracy or completeness of any content on the Platform.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">11. Limitation of Liability</h3>
              <p>To the maximum extent permitted by law, Perk Exchange and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits, data, or use, arising out of:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>Your use or inability to use the Platform.</li>
                <li>Any transactions or interactions with other users.</li>
                <li>Unauthorized access to or alteration of your data.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">12. Indemnification</h3>
              <p>You agree to indemnify, defend, and hold harmless Perk Exchange, its affiliates, and its employees from and against any claims, liabilities, damages, losses, and expenses, including legal fees, arising out of or related to:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>Your use of the Platform.</li>
                <li>Your violation of these Terms.</li>
                <li>Your violation of any third-party rights or applicable laws.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">13. Termination</h3>
              <p>Perk Exchange reserves the right to suspend or terminate your account at any time for any reason, including but not limited to:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>Violation of these Terms.</li>
                <li>Engagement in prohibited conduct.</li>
                <li>Legal or regulatory concerns.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">14. Governing Law and Dispute Resolution</h3>
              <p>These Terms are governed by the laws of the State of California. Any disputes arising under these Terms shall be resolved exclusively through binding arbitration in Los Angeles, California, unless prohibited by applicable law.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">15. Changes to Terms</h3>
              <p>Perk Exchange reserves the right to update these Terms at any time. You will be notified of significant changes, and your continued use of the Platform after changes are posted constitutes your acceptance of the revised Terms.</p>
            </div>

            <div>
              <h3 className="text-lg font-semibold mt-6 mb-3">16. Contact Information</h3>
              <p>For questions or concerns about these Terms, please contact us at:</p>
              <p>Email: admin@perk-exchange.com</p>
              <p>Address: 500 N. Brand Blvd. Suite 890 Glendale CA 91203</p>
            </div>
          </div>
        </div>

        <div className="p-6 border-t bg-gray-50 space-y-4">
          {!scrolledToBottom && (
            <div className="text-sm text-gray-600 mb-4">
              Please scroll through the entire document to continue
            </div>
          )}
          <div className="flex items-start gap-2">
            <input
              type="checkbox"
              id="read-terms"
              checked={hasRead}
              onChange={(e) => setHasRead(e.target.checked)}
              className="mt-1 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              disabled={!scrolledToBottom}
            />
            <label htmlFor="read-terms" className="text-sm text-gray-700">
              I confirm that I have read and understand these terms, and I acknowledge that using Perk Exchange without complying with my employer's policies may result in disciplinary action
            </label>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Please type your full name to confirm: {fullName}
            </label>
            <input
              type="text"
              value={nameConfirmation}
              onChange={(e) => setNameConfirmation(e.target.value)}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Your full name"
              disabled={!scrolledToBottom}
            />
          </div>

          <div className="flex justify-end gap-4 pt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={onAccept}
              disabled={!canAccept}
              className={`px-4 py-2 text-sm font-medium text-white rounded-md ${
                canAccept
                  ? 'bg-blue-600 hover:bg-blue-700'
                  : 'bg-gray-300 cursor-not-allowed'
              }`}
            >
              Accept & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;