// src/components/Marketplace.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFriends } from '../redux/slices/friendSlice';
import { Link, useNavigate } from 'react-router-dom';
import { showToast } from '../utils/toast';
import { fetchPerks } from '../redux/slices/perkSlice';
import { createBid } from '../redux/slices/bidSlice';
import { 
  Card, 
  CardHeader, 
  CardContent, 
  CardFooter, 
  CardTitle, 
  CardDescription 
} from "./ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

const Marketplace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedUserPerk, setSelectedUserPerk] = useState({});
  const [bidQuantities, setBidQuantities] = useState({});

  // Get data from Redux store
  const perks = useSelector((state) => state.perks.items);
  const userPerks = useSelector((state) => 
    state.perks.items.filter(perk => perk.userId._id === state.auth.user?._id) || []
  );
  const bids = useSelector((state) => state.bids.items);
  const currentUserId = useSelector((state) => state.auth.user?._id);
  const friends = useSelector((state) => state.friends.friends);
  
  // Get array of friend IDs
  const friendIds = friends.map(friend => 
    friend.requesterId._id === currentUserId ? 
      friend.recipientId._id : 
      friend.requesterId._id
  );

  useEffect(() => {
    dispatch(fetchPerks());
    dispatch(getFriends());

  }, [dispatch]);

  // Filter out user's own perks and check discoverability
  const otherUsersPerks = perks.filter(perk => {
  const isOwnPerk = perk.userId._id === currentUserId;
  // Default to public if discoverability is not set
  const isPublic = !perk.userId.discoverability || perk.userId.discoverability === 'public';
  const isFriend = friendIds.includes(perk.userId._id);
  
  return !isOwnPerk && (isPublic || isFriend);
});

  const hasExistingBid = (marketPerkId) => {
    return bids.some(bid => 
      bid.offeredPerkId === marketPerkId && 
      bid.status === 'pending'
    );
  };

  const handleUserPerkChange = (marketPerkId, userPerkId) => {
    setSelectedUserPerk({ ...selectedUserPerk, [marketPerkId]: userPerkId });
    setBidQuantities({ ...bidQuantities, [marketPerkId]: 1 });
  };

  const handleBidQuantityChange = (marketPerkId, quantity) => {
    setBidQuantities({ ...bidQuantities, [marketPerkId]: quantity });
  };

  const handleBid = async (marketPerkId, e) => {
    e.preventDefault();
    try {
      await showToast.promise(
        dispatch(createBid({
          offeredPerkId: marketPerkId,
          bidPerkId: selectedUserPerk[marketPerkId],
          quantity: bidQuantities[marketPerkId]
        })).unwrap(),
        {
          loading: 'Placing your bid...',
          success: 'Bid placed successfully!',
          error: 'Failed to place bid. Please try again.'
        }
      );
      setSelectedUserPerk({ ...selectedUserPerk, [marketPerkId]: '' });
      setBidQuantities({ ...bidQuantities, [marketPerkId]: 1 });
    } catch (error) {
      console.error('Error in handleBid:', error);
    }
  };

  const handleProfileClick = (userId, e) => {
    e.preventDefault();
    navigate(`/profile/${userId}`);
  };

  // Don't render until we have the perks data
  if (!perks.length) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 text-center">
        Loading marketplace...
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-3xl font-bold text-gray-900">Available Perks</h2>
        <div className="flex gap-4">
          <select className="px-4 py-2 border rounded-lg text-gray-700">
            <option>Sort by Value</option>
            <option>Sort by Company</option>
            <option>Sort by Recent</option>
          </select>
        </div>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {otherUsersPerks.map((perk) => {
          const existingBid = hasExistingBid(perk._id);
          const owner = perk.userId;
          
          return (
            <Card key={perk._id} className="hover:shadow-lg transition-shadow duration-300">
              <CardHeader>
                <CardTitle>{perk.title}</CardTitle>
                <CardDescription className="flex justify-between items-center">
                  <span>{owner.company || 'Company'}</span>
                  <span className="text-blue-600 font-semibold">${perk.estimatedValue}</span>
                </CardDescription>
              </CardHeader>
              
              <CardContent>
                <p className="text-gray-600 mb-4">{perk.description}</p>
                <div className="flex items-center gap-2 text-sm text-gray-500">
                  <Link 
                    to={`/profile/${owner._id}`}
                    onClick={(e) => handleProfileClick(owner._id, e)}
                    className="hover:text-blue-600 hover:underline"
                  >
                    Posted by: {owner.username}
                  </Link>
                  {owner.averageRating && (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <div className="flex items-center gap-1 cursor-help">
                            <span className="text-yellow-400">★</span>
                            <span>{owner.averageRating.toFixed(1)}</span>
                            <span className="text-gray-500">
                              ({owner.totalRatings})
                            </span>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <div className="p-2">
                            <p className="font-medium mb-1">Exchange History</p>
                            <p className="text-sm">Successfully completed {owner.totalRatings} exchanges</p>
                            <p className="text-sm text-gray-400">Average rating: {owner.averageRating.toFixed(1)} / 5</p>
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </div>
              </CardContent>

              <CardFooter>
                {existingBid ? (
                  <div className="w-full p-3 bg-gray-50 rounded-lg text-gray-600 text-center">
                    Bid Already Placed
                  </div>
                ) : (
                  <form onSubmit={(e) => handleBid(perk._id, e)} className="w-full space-y-3">
                    <select 
                      onChange={(e) => handleUserPerkChange(perk._id, e.target.value)}
                      value={selectedUserPerk[perk._id] || ''}
                      className="w-full p-2 border rounded-lg"
                    >
                      <option value="">Select your perk to offer</option>
                      {userPerks.map((userPerk) => (
                        <option key={userPerk._id} value={userPerk._id}>
                          {userPerk.title} (${userPerk.estimatedValue})
                        </option>
                      ))}
                    </select>
                    
                    {selectedUserPerk[perk._id] && (
                      <input 
                        type="number" 
                        min="1"
                        placeholder="Quantity"
                        value={bidQuantities[perk._id] || 1}
                        onChange={(e) => handleBidQuantityChange(perk._id, parseInt(e.target.value))}
                        className="w-full p-2 border rounded-lg"
                      />
                    )}
                    
                    <button 
                      type="submit"
                      disabled={!selectedUserPerk[perk._id]}
                      className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                    >
                      Place Bid
                    </button>
                  </form>
                )}
              </CardFooter>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default Marketplace;