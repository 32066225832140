// frontend/src/components/AcceptedBids.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, CardContent, CardFooter, CardTitle, CardDescription } from './ui/card';
import RatingModal from './RatingModal';
import Chat from './Chat';
import { showToast } from '../utils/toast';
import SupportModal from './SupportModal';
import { 
  fetchExchanges, 
  updateExchange, 
  initiateCompletion, 
  confirmCompletion 
} from '../redux/slices/exchangeSlice';
import { createSupportTicket } from '../redux/slices/supportSlice';
import { fetchUnreadCounts } from '../redux/slices/chatSlice';

const AcceptedBids = () => {
  const dispatch = useDispatch();
  const [extendDays, setExtendDays] = useState({});
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState(null);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [activeChatExchange, setActiveChatExchange] = useState(null);

  // Get data from Redux store
  const currentUser = useSelector((state) => state.auth.user);
  const exchanges = useSelector((state) => state.exchanges.items);
  const loading = useSelector((state) => state.exchanges.loading);

  useEffect(() => {
    dispatch(fetchExchanges());
    // Initial fetch of unread counts
    dispatch(fetchUnreadCounts());

    // Set up polling for unread counts
    const pollInterval = setInterval(() => {
      dispatch(fetchUnreadCounts());
    }, 30000); // Poll every 30 seconds

    return () => clearInterval(pollInterval);
  }, [dispatch]);

  // Filter exchanges by status
  const ongoingExchanges = exchanges.filter(exchange => exchange.status === 'ongoing');
  const pendingCompletionExchanges = exchanges.filter(exchange => exchange.status === 'pending_completion');
  const completedExchanges = exchanges.filter(exchange => exchange.status === 'completed');

  const handleExtendTimeline = async (exchangeId, days) => {
    if (days && days > 0) {
      const newCompletionDate = new Date();
      newCompletionDate.setDate(newCompletionDate.getDate() + parseInt(days));
      try {
        await showToast.promise(
          dispatch(updateExchange({ 
            id: exchangeId, 
            completionDate: newCompletionDate.toISOString() 
          })).unwrap(),
          {
            loading: 'Extending timeline...',
            success: 'Timeline extended successfully!',
            error: 'Failed to extend timeline. Please try again.'
          }
        );
        setExtendDays({ ...extendDays, [exchangeId]: '' });
      } catch (error) {
        console.error('Error extending timeline:', error);
      }
    }
  };

  const handleOpenRating = (exchange) => {
    setSelectedExchange(exchange);
    setIsRatingModalOpen(true);
  };

  const handleCloseRating = () => {
    setSelectedExchange(null);
    setIsRatingModalOpen(false);
  };

   const handleSubmitRating = async (ratingData) => {
    try {
      const { exchangeId, rating, comment } = ratingData;
      await showToast.promise(
        dispatch(updateExchange({
          id: exchangeId,
          rating,
          comment
        })).unwrap(),
        {
          loading: 'Submitting rating...',
          success: 'Rating submitted successfully!',
          error: 'Failed to submit rating. Please try again.'
        }
      );
      handleCloseRating();
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const handleOpenSupport = (exchange) => {
    setSelectedExchange(exchange);
    setIsSupportModalOpen(true);
  };

  const handleCloseSupport = () => {
    setSelectedExchange(null);
    setIsSupportModalOpen(false);
  };

  const handleSubmitSupport = async ({ exchangeId, description }) => {
    try {
      await showToast.promise(
        dispatch(createSupportTicket({ exchangeId, description })).unwrap(),
        {
          loading: 'Submitting support request...',
          success: 'Support request submitted successfully!',
          error: 'Failed to submit request. Please try again.'
        }
      );
      handleCloseSupport();
    } catch (error) {
      console.error('Error submitting support request:', error);
    }
  };

  const handleMarkComplete = async (exchangeId) => {
    try {
      const exchange = exchanges.find(ex => ex._id === exchangeId);
      if (!exchange) return;

      const action = exchange.status === 'ongoing' ? initiateCompletion : confirmCompletion;
      
      await showToast.promise(
        dispatch(action(exchangeId)).unwrap(),
        {
          loading: 'Processing completion...',
          success: exchange.status === 'ongoing' ? 
            'Completion request sent. Waiting for other party to confirm.' : 
            'Exchange completed successfully!',
          error: 'Failed to complete exchange. Please try again.'
        }
      );
    } catch (error) {
      console.error('Error completing exchange:', error);
    }
  };

  const handleInitiateChat = (exchange) => {
    console.log('Opening chat for exchange:', exchange._id);
    setActiveChatExchange(exchange);
  };

  const handleCloseChat = () => {
    setActiveChatExchange(null);
  };

  const ExchangeCard = ({ exchange }) => {
    // Get unread counts from Redux store
    const unreadCounts = useSelector((state) => state.chat.unreadCounts);
    const unreadCount = unreadCounts[exchange._id] || 0;

    const isUserBidder = exchange.bidId.bidderId._id === currentUser._id;
    const otherUser = isUserBidder 
      ? exchange.bidId.offeredPerkId.userId 
      : exchange.bidId.bidderId;
    const isUserInitiator = exchange.completionInitiator === currentUser._id;

    const userRating = isUserBidder ? exchange.ratings?.bidder : exchange.ratings?.offerer;
    const otherUserRating = isUserBidder ? exchange.ratings?.offerer : exchange.ratings?.bidder;
    const canRate = exchange.status === 'completed' && !userRating?.rating;

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    const getStatusBadge = () => {
      const badges = {
        ongoing: "bg-blue-100 text-blue-800",
        pending_completion: "bg-yellow-100 text-yellow-800",
        completed: "bg-green-100 text-green-800"
      };

      return (
        <span className={`px-2 py-1 rounded-full text-sm font-medium ${badges[exchange.status]}`}>
          {exchange.status === 'pending_completion' ? 'Pending Completion' : 
           exchange.status.charAt(0).toUpperCase() + exchange.status.slice(1)}
        </span>
      );
    };

    const renderRatings = () => {
      if (exchange.status !== 'completed') return null;

      return (
        <div className="mt-4 space-y-3 border-t pt-4">
          <h4 className="font-medium text-gray-900">Exchange Ratings</h4>
          {userRating?.rating ? (
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600">Your rating:</span>
              <div className="flex items-center">
                {'★'.repeat(userRating.rating)}{'☆'.repeat(5-userRating.rating)}
              </div>
              {userRating.comment && (
                <p className="text-sm text-gray-600">"{userRating.comment}"</p>
              )}
            </div>
          ) : canRate && (
            <button
              onClick={() => handleOpenRating(exchange)}
              className="text-sm text-blue-600 hover:text-blue-700"
            >
              Rate this exchange
            </button>
          )}
          
          {otherUserRating?.rating && (
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-600">{otherUser.name}'s rating:</span>
              <div className="flex items-center">
                {'★'.repeat(otherUserRating.rating)}{'☆'.repeat(5-otherUserRating.rating)}
              </div>
              {otherUserRating.comment && (
                <p className="text-sm text-gray-600">"{otherUserRating.comment}"</p>
              )}
            </div>
          )}
        </div>
      );
    };

    return (
      <Card className="hover:shadow-lg transition-shadow duration-300">
        <CardHeader>
          <div className="flex justify-between items-start">
            <div className="flex items-start space-x-2">
              <CardTitle>{exchange.bidId.bidPerkId.title}</CardTitle>
              {unreadCount > 0 && (
                <span className="flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs text-white">
                  {unreadCount > 9 ? '9+' : unreadCount}
                </span>
              )}
            </div>
            {getStatusBadge()}
          </div>
          <CardDescription>
            Exchanging with {otherUser.name} ({otherUser.company})
          </CardDescription>
        </CardHeader>

        <CardContent className="space-y-4">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm text-gray-600">Your part:</p>
              <p className="font-medium">{exchange.bidId.quantity}x {exchange.bidId.bidPerkId.title}</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-600">In exchange for:</p>
              <p className="font-medium">{exchange.bidId.offeredPerkId.title}</p>
            </div>
          </div>

          <div className="border-t pt-4">
            <p className="text-sm text-gray-600">Completion Date: {formatDate(exchange.completionDate)}</p>
            <p className="text-sm text-gray-600 mt-2">Logistics: {exchange.logistics}</p>
          </div>

          {renderRatings()}
        </CardContent>

        <CardFooter className="flex flex-wrap gap-3">
          <div className="relative">
            <button
              onClick={() => handleInitiateChat(exchange)}
              className="px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              Chat with {otherUser.name}
              {unreadCount > 0 && (
                <span className="absolute -top-2 -right-2 flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs text-white">
                  {unreadCount > 9 ? '9+' : unreadCount}
                </span>
              )}
            </button>
          </div>

          {exchange.status === 'ongoing' && (
            <>
              <button
                onClick={() => handleMarkComplete(exchange._id)}
                className="px-3 py-1.5 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
              >
                Mark as Complete
              </button>

              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  placeholder="Days"
                  value={extendDays[exchange._id] || ''}
                  onChange={(e) => setExtendDays({ 
                    ...extendDays, 
                    [exchange._id]: e.target.value 
                  })}
                  className="w-20 px-2 py-1 border rounded-md"
                  min="1"
                />
                <button
                  onClick={() => handleExtendTimeline(exchange._id, extendDays[exchange._id])}
                  className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  disabled={!extendDays[exchange._id]}
                >
                  Extend
                </button>
              </div>
            </>
          )}

          {exchange.status === 'pending_completion' && !isUserInitiator && (
            <button
              onClick={() => handleMarkComplete(exchange._id)}
              className="px-3 py-1.5 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
            >
              Confirm Completion
            </button>
          )}

          <button
            onClick={() => handleOpenSupport(exchange)}
            className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Request Support
          </button>
        </CardFooter>
      </Card>
    );
  };

  const EmptyState = ({ message }) => (
    <div className="text-center py-12 bg-gray-50 rounded-lg">
      <h3 className="text-lg font-medium text-gray-900 mb-2">No exchanges to show</h3>
      <p className="text-gray-600">{message}</p>
    </div>
  );

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 text-center">
        Loading exchanges...
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="space-y-8">
        <section>
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Ongoing Exchanges</h2>
          {ongoingExchanges.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {ongoingExchanges.map(exchange => (
                <ExchangeCard key={exchange._id} exchange={exchange} />
              ))}
            </div>
          ) : (
            <EmptyState message="You don't have any ongoing exchanges at the moment." />
          )}
        </section>

        {pendingCompletionExchanges.length > 0 && (
          <section>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Pending Completion</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {pendingCompletionExchanges.map(exchange => (
                <ExchangeCard key={exchange._id} exchange={exchange} />
              ))}
            </div>
          </section>
        )}

        <section>
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Completed Exchanges</h2>
          {completedExchanges.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {completedExchanges.map(exchange => (
                <ExchangeCard key={exchange._id} exchange={exchange} />
              ))}
            </div>
          ) : (
            <EmptyState message="You haven't completed any exchanges yet." />
          )}
        </section>
      </div>

      <RatingModal
        isOpen={isRatingModalOpen}
        onClose={handleCloseRating}
        onSubmit={handleSubmitRating}
        exchange={selectedExchange}
        currentUser={currentUser}
      />

      <SupportModal
        isOpen={isSupportModalOpen}
        onClose={handleCloseSupport}
        onSubmit={handleSubmitSupport}
        exchange={selectedExchange}
        currentUser={currentUser}
      />

      {activeChatExchange && (
        <Chat
          currentUser={currentUser}
          otherUser={activeChatExchange.bidId.bidderId._id === currentUser._id 
            ? activeChatExchange.bidId.offeredPerkId.userId 
            : activeChatExchange.bidId.bidderId}
          exchangeId={activeChatExchange._id}
          onClose={handleCloseChat}
        />
      )}
    </div>  
  );
};

export default AcceptedBids;