// src/components/AuthProvider.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile } from '../redux/slices/authSlice';

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, user, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    // Check if we have a token but no user data
    const token = localStorage.getItem('token');
    if (token && !user && !loading) {
      dispatch(fetchUserProfile());
    }
  }, [dispatch, user, loading]);

  return <>{children}</>;
};

export default AuthProvider;