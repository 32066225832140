import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../utils/toast';
import { Card, CardHeader, CardContent, CardFooter, CardTitle, CardDescription } from './ui/card';
import { fetchBids, updateBid, deleteBid, acceptBid, rejectBid } from '../redux/slices/bidSlice';
import { Gift, Check, X, Edit2, Trash2, Save } from 'lucide-react';

const BidCard = ({ bid, type, onUpdate, onDelete, onAccept, onReject }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editQuantity, setEditQuantity] = useState(bid.quantity);

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    onUpdate(bid._id, editQuantity);
    setIsEditing(false);
  };

  const exchangeValue = bid.quantity * (bid.bidPerkId?.estimatedValue || 0);

  return (
    <Card className="group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
      <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-2">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Gift className="w-6 h-6 text-blue-500" />
          </div>
          <div>
            <CardTitle className="text-lg font-semibold">
              {type === 'placed' ? 'Your bid' : 'Received bid'}
            </CardTitle>
            <CardDescription>
              {new Date(bid.createdAt).toLocaleDateString()}
            </CardDescription>
          </div>
        </div>
        <span className="px-2 py-1 bg-blue-100 text-blue-800 text-sm font-medium rounded-full">
          Pending
        </span>
      </CardHeader>

      <CardContent>
        <div className="flex justify-between items-start border-b pb-4">
          <div>
            <p className="font-medium text-gray-900">{bid.bidPerkId?.title}</p>
            <p className="text-sm text-gray-600">Quantity: {bid.quantity}x</p>
            <p className="text-sm text-gray-600">Value: ${exchangeValue}</p>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-600">In exchange for</p>
            <p className="font-medium text-gray-900">{bid.offeredPerkId?.title}</p>
            <p className="text-sm text-gray-600">Value: ${bid.offeredPerkId?.estimatedValue}</p>
          </div>
        </div>

        <div className="mt-4 text-sm text-gray-600">
          {type === 'placed' ? (
            <p>Sent to: {bid.offeredPerkId?.userId?.name}</p>
          ) : (
            <p>From: {bid.bidderId?.name}</p>
          )}
        </div>

        {isEditing && (
          <form onSubmit={handleUpdateSubmit} className="mt-4 flex items-center gap-2">
            <input
              type="number"
              min="1"
              value={editQuantity}
              onChange={(e) => setEditQuantity(parseInt(e.target.value))}
              className="w-24 px-2 py-1 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <button
              type="submit"
              className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
            >
              <Save className="w-4 h-4 mr-2" />
              Save
            </button>
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              <X className="w-4 h-4 mr-2" />
              Cancel
            </button>
          </form>
        )}
      </CardContent>

      <CardFooter className="justify-end space-x-2">
        {type === 'placed' && !isEditing && (
          <>
            <button
              onClick={() => setIsEditing(true)}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              <Edit2 className="w-4 h-4 mr-2" />
              Edit
            </button>
            <button
              onClick={() => onDelete(bid._id)}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
            >
              <Trash2 className="w-4 h-4 mr-2" />
              Delete
            </button>
          </>
        )}

        {type === 'received' && (
          <>
            <button
              onClick={() => onAccept(bid)}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
            >
              <Check className="w-4 h-4 mr-2" />
              Accept
            </button>
            <button
              onClick={() => onReject(bid)}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
            >
              <X className="w-4 h-4 mr-2" />
              Reject
            </button>
          </>
        )}
      </CardFooter>
    </Card>
  );
};

const EmptyState = ({ message }) => (
  <div className="text-center py-12 bg-gray-50 rounded-lg">
    <Gift className="w-12 h-12 text-gray-400 mx-auto mb-4" />
    <h3 className="text-lg font-medium text-gray-900 mb-2">No bids to show</h3>
    <p className="text-gray-600">{message}</p>
  </div>
);

const MyBids = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { items: bids, loading } = useSelector((state) => state.bids);

  useEffect(() => {
    dispatch(fetchBids());
  }, [dispatch]);

  const placedBids = bids?.filter(bid => 
    bid?.bidderId?._id === user?._id && 
    bid?.status === 'pending'
  ) || [];
  
  const receivedBids = bids?.filter(bid => 
    bid?.offeredPerkId?.userId?._id === user?._id && 
    bid?.status === 'pending'
  ) || [];
  
  const rejectedBids = bids?.filter(bid => 
    (bid?.bidderId?._id === user?._id || bid?.offeredPerkId?.userId?._id === user?._id) && 
    bid?.status === 'rejected'
  ) || [];

  const handleUpdateBid = async (bidId, quantity) => {
    try {
      await showToast.promise(
        dispatch(updateBid({ bidId, quantity })).unwrap(),
        {
          loading: 'Updating bid...',
          success: 'Bid updated successfully',
          error: 'Failed to update bid'
        }
      );
    } catch (error) {
      console.error('Error updating bid:', error);
    }
  };

  const handleDeleteBid = async (bidId) => {
    if (window.confirm('Are you sure you want to delete this bid?')) {
      try {
        await showToast.promise(
          dispatch(deleteBid(bidId)).unwrap(),
          {
            loading: 'Deleting bid...',
            success: 'Bid deleted successfully',
            error: 'Failed to delete bid'
          }
        );
      } catch (error) {
        console.error('Error deleting bid:', error);
      }
    }
  };

  const handleAcceptBid = async (bid) => {
    try {
      await showToast.promise(
        dispatch(acceptBid(bid._id)).unwrap(),
        {
          loading: 'Accepting bid...',
          success: 'Bid accepted successfully',
          error: 'Failed to accept bid'
        }
      );
    } catch (error) {
      console.error('Error accepting bid:', error);
    }
  };

  const handleRejectBid = async (bid) => {
    try {
      await showToast.promise(
        dispatch(rejectBid(bid._id)).unwrap(),
        {
          loading: 'Rejecting bid...',
          success: 'Bid rejected successfully',
          error: 'Failed to reject bid'
        }
      );
    } catch (error) {
      console.error('Error rejecting bid:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="mb-8">
        <section className="mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Bids You've Placed</h2>
          {placedBids.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {placedBids.map(bid => (
                <BidCard
                  key={bid._id}
                  bid={bid}
                  type="placed"
                  onUpdate={handleUpdateBid}
                  onDelete={handleDeleteBid}
                />
              ))}
            </div>
          ) : (
            <EmptyState message="You haven't placed any bids yet. Browse the marketplace to find perks to exchange!" />
          )}
        </section>

        <section className="mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Bids You've Received</h2>
          {receivedBids.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {receivedBids.map(bid => (
                <BidCard
                  key={bid._id}
                  bid={bid}
                  type="received"
                  onAccept={handleAcceptBid}
                  onReject={handleRejectBid}
                />
              ))}
            </div>
          ) : (
            <EmptyState message="You haven't received any bids yet. Add more perks to attract exchanges!" />
          )}
        </section>

        {rejectedBids.length > 0 && (
          <section>
            <h2 className="text-3xl font-bold text-gray-900 mb-8">Rejected Bids</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {rejectedBids.map(bid => (
                <BidCard
                  key={bid._id}
                  bid={bid}
                  type="rejected"
                />
              ))}
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default MyBids;