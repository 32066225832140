// src/components/Profile.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile, updateUserProfile } from '../redux/slices/authSlice';
import { showToast } from '../utils/toast';
import { Card, CardHeader, CardContent, CardTitle } from './ui/card';

const Profile = () => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const { user, loading, error } = useSelector((state) => state.auth);
  
  const [formData, setFormData] = useState({
    name: '',
    city: '',
    company: '',
    jobTitle: '',
    linkedin: '',
    discoverability: 'public'
  });

  useEffect(() => {
    // Only fetch if we don't have user data
    if (!user) {
      dispatch(fetchUserProfile());
    }
  }, [dispatch, user]);

  useEffect(() => {
    // Update form data when user data is available
    if (user) {
      setFormData({
        name: user.name || '',
        city: user.city || '',
        company: user.company || '',
        jobTitle: user.jobTitle || '',
        linkedin: user.linkedin || '',
        discoverability: user.discoverability || 'public'
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // If switching to private mode and no invite code exists, generate one
      const updatedData = {
        ...formData,
        generateNewInviteCode: formData.discoverability === 'private' && !user.inviteCode
      };

      await showToast.promise(
        dispatch(updateUserProfile(updatedData)).unwrap(),
        {
          loading: 'Updating profile...',
          success: 'Profile updated successfully!',
          error: 'Failed to update profile. Please try again.'
        }
      );
      setIsEditing(false);
      // Refresh user data after update
      dispatch(fetchUserProfile());
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <Card>
          <CardContent className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <Card>
          <CardContent className="text-center p-6">
            <p className="text-red-600">Error loading profile. Please try refreshing the page.</p>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <Card>
          <CardContent className="text-center p-6">
            <p>No user data found. Please try logging in again.</p>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle>Profile</CardTitle>
        </CardHeader>
        <CardContent>
          {isEditing ? (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Full Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                  Company
                </label>
                <input
                  id="company"
                  name="company"
                  type="text"
                  value={formData.company}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  City
                </label>
                <input
                  id="city"
                  name="city"
                  type="text"
                  value={formData.city}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700">
                  Job Title
                </label>
                <input
                  id="jobTitle"
                  name="jobTitle"
                  type="text"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label htmlFor="linkedin" className="block text-sm font-medium text-gray-700">
                  LinkedIn URL
                </label>
                <input
                  id="linkedin"
                  name="linkedin"
                  type="url"
                  value={formData.linkedin}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                />
              </div>

              <div>
                <label htmlFor="discoverability" className="block text-sm font-medium text-gray-700">
                  Profile Visibility
                </label>
                <select
                  id="discoverability"
                  name="discoverability"
                  value={formData.discoverability}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </select>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                >
                  Save Changes
                </button>
              </div>
            </form>
          ) : (
            <div>
              <div className="mb-8">
                <img
                  src={`https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}&background=0D8ABC&color=fff`}
                  alt={user.name}
                  className="w-24 h-24 rounded-full mb-4"
                />
                <h2 className="text-2xl font-bold text-gray-900">{user.name}</h2>
                <p className="text-gray-600">{user.email}</p>
              </div>

              <dl className="grid grid-cols-1 gap-4">
                <div className="mb-4">
                  <dt className="text-sm font-medium text-gray-500">Username</dt>
                  <dd className="mt-1 text-sm text-gray-900">{user.username}</dd>
                </div>
                <div className="mb-4">
                  <dt className="text-sm font-medium text-gray-500">Company</dt>
                  <dd className="mt-1 text-sm text-gray-900">{user.company || 'Not specified'}</dd>
                </div>
                <div className="mb-4">
                  <dt className="text-sm font-medium text-gray-500">City</dt>
                  <dd className="mt-1 text-sm text-gray-900">{user.city || 'Not specified'}</dd>
                </div>
                <div className="mb-4">
                  <dt className="text-sm font-medium text-gray-500">Job Title</dt>
                  <dd className="mt-1 text-sm text-gray-900">{user.jobTitle || 'Not specified'}</dd>
                </div>
                {user.linkedin && (
                  <div className="mb-4">
                    <dt className="text-sm font-medium text-gray-500">LinkedIn</dt>
                    <dd className="mt-1 text-sm text-blue-600">
                      <a 
                        href={user.linkedin} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        View Profile
                      </a>
                    </dd>
                  </div>
                )}
                <div className="mb-4">
                  <dt className="text-sm font-medium text-gray-500">Profile Visibility</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {user.discoverability === 'private' ? 'Private' : 'Public'}
                  </dd>
                </div>
                {user.discoverability === 'private' && (
                  <div className="mb-4">
                    <dt className="text-sm font-medium text-gray-500">Invite Code</dt>
                    <dd className="mt-1 text-sm text-gray-900 flex items-center gap-2">
                      <span className="font-mono bg-gray-100 px-2 py-1 rounded">
                        {user.inviteCode || 'Generating...'}
                      </span>
                      {user.inviteCode && (
                        <button
                          type="button"
                          onClick={() => {
                            navigator.clipboard.writeText(user.inviteCode);
                            showToast.success('Invite code copied to clipboard!');
                          }}
                          className="px-2 py-1 text-xs font-medium text-blue-600 bg-blue-50 rounded hover:bg-blue-100"
                        >
                          Copy
                        </button>
                      )}
                    </dd>
                  </div>
                )}
              </dl>

              <button
                onClick={() => setIsEditing(true)}
                className="mt-6 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Edit Profile
              </button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Profile;