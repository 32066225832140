// frontend/src/components/Chat.js
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMessages, sendMessage, clearMessages, markMessagesRead } from '../redux/slices/chatSlice';
import { showToast } from '../utils/toast';

const Chat = ({ currentUser, otherUser, exchangeId, onClose }) => {
  const dispatch = useDispatch();
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const chatMessages = useSelector((state) => state.chat.messages[exchangeId] || []);
  const loading = useSelector((state) => state.chat.loading);

  useEffect(() => {
    dispatch(fetchMessages(exchangeId));
    dispatch(markMessagesRead(exchangeId));

    const pollInterval = setInterval(() => {
      dispatch(fetchMessages(exchangeId));
    }, 10000);

    return () => {
      clearInterval(pollInterval);
      dispatch(clearMessages(exchangeId));
    };
  }, [dispatch, exchangeId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    try {
      await dispatch(sendMessage({
        exchangeId,
        content: newMessage.trim()
      })).unwrap();
      
      setNewMessage('');
    } catch (error) {
      showToast.error('Failed to send message');
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  const formatTime = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl flex flex-col h-[80vh]">
        {/* Chat Header */}
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-xl font-semibold">Chat with {otherUser.name}</h2>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700 text-2xl font-bold w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100"
            aria-label="Close chat"
          >
            ×
          </button>
        </div>

        {/* Messages Area */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {loading && chatMessages.length === 0 ? (
            <div className="text-center text-gray-500">Loading messages...</div>
          ) : chatMessages.length === 0 ? (
            <div className="text-center text-gray-500">No messages yet</div>
          ) : (
            chatMessages.map((message) => (
              <div
                key={message._id}
                className={`flex ${
                  message.senderId._id === currentUser._id
                    ? 'justify-end'
                    : 'justify-start'
                }`}
              >
                <div
                  className={`max-w-[70%] rounded-lg p-3 ${
                    message.senderId._id === currentUser._id
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-900'
                  }`}
                >
                  <div className="text-sm font-medium mb-1">
                    {message.senderId._id === currentUser._id
                      ? 'You'
                      : message.senderId.name}
                  </div>
                  <div className="break-words">{message.content}</div>
                  <div
                    className={`text-xs mt-1 ${
                      message.senderId._id === currentUser._id
                        ? 'text-blue-100'
                        : 'text-gray-500'
                    }`}
                  >
                    {formatTime(message.createdAt)}
                  </div>
                </div>
              </div>
            ))
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Message Input */}
        <form onSubmit={handleSendMessage} className="p-4 border-t">
          <div className="flex gap-2">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type your message..."
              className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              maxLength={1000}
            />
            <button
              type="submit"
              disabled={!newMessage.trim()}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              Send
            </button>
          </div>
          <div className="text-xs text-gray-500 mt-1">
            {newMessage.length}/1000 characters
          </div>
        </form>
      </div>
    </div>
  );
};

export default Chat;