import React, { useState, useEffect } from 'react';

const RatingModal = ({ isOpen, onClose, onSubmit, exchange, currentUser }) => {
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (isOpen) {
      setRating(0);
      setHoveredRating(0);
      setComment('');
    }
  }, [isOpen]);

  if (!isOpen || !exchange) return null;

  const isUserBidder = exchange.bidId.bidderId._id === currentUser._id;
  const otherUser = isUserBidder 
    ? exchange.bidId.offeredPerkId.userId 
    : exchange.bidId.bidderId;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
        exchangeId: exchange._id,
        rating: rating,
        comment: comment.trim() || null
    });
  };

  const StarRating = () => {
    return (
      <div className="flex items-center gap-1">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            type="button"
            onClick={() => setRating(star)}
            onMouseEnter={() => setHoveredRating(star)}
            onMouseLeave={() => setHoveredRating(0)}
            className="text-2xl focus:outline-none transition-colors"
          >
            {star <= (hoveredRating || rating) ? (
              <span className="text-yellow-400">★</span>
            ) : (
              <span className="text-gray-300">☆</span>
            )}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full">
        <div className="p-6 border-b">
          <h2 className="text-xl font-semibold text-gray-900">
            Rate Your Exchange Experience
          </h2>
          <p className="mt-1 text-sm text-gray-600">
            Rate your exchange with {otherUser.name}
          </p>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">
              How would you rate this exchange?
            </label>
            <StarRating />
            {rating > 0 && (
              <p className="mt-2 text-sm text-gray-600">
                {rating === 5 ? 'Excellent!' :
                 rating === 4 ? 'Very Good' :
                 rating === 3 ? 'Good' :
                 rating === 2 ? 'Fair' :
                 'Poor'}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="comment" className="block text-sm font-medium text-gray-700 mb-2">
              Additional Comments (Optional)
            </label>
            <textarea
              id="comment"
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Share your experience with this exchange..."
              className="w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              maxLength={1000}
            />
            <p className="mt-1 text-sm text-gray-500">
              {comment.length}/1000 characters
            </p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">What You're Rating</h3>
            <div className="bg-gray-50 rounded-md p-4 text-sm">
              <p>Exchange: {exchange.bidId.bidPerkId.title}</p>
              <p className="text-gray-600">
                for {exchange.bidId.offeredPerkId.title}
              </p>
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!rating}
              className={`px-4 py-2 text-sm font-medium text-white rounded-md ${
                rating
                  ? 'bg-blue-600 hover:bg-blue-700'
                  : 'bg-gray-300 cursor-not-allowed'
              }`}
            >
              Submit Rating
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RatingModal;