// src/components/MyBids.js
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../utils/toast';
import { Card, CardHeader, CardContent, CardFooter, CardTitle, CardDescription } from './ui/card';
import { fetchBids, updateBid, deleteBid, acceptBid, rejectBid } from '../redux/slices/bidSlice';

const MyBids = () => {
  const dispatch = useDispatch();
  const [editingBid, setEditingBid] = useState(null);
  const [editQuantity, setEditQuantity] = useState(1);

  // Get data from Redux store
  const { user } = useSelector((state) => state.auth);
  const { items: bids, loading } = useSelector((state) => state.bids);

  useEffect(() => {
    dispatch(fetchBids());
  }, [dispatch]);

  // Filter bids into categories with null checks
  const placedBids = bids?.filter(bid => 
    bid?.bidderId?._id === user?._id && 
    bid?.status === 'pending'
  ) || [];
  
  const receivedBids = bids?.filter(bid => 
    bid?.offeredPerkId?.userId?._id === user?._id && 
    bid?.status === 'pending'
  ) || [];
  
  const rejectedBids = bids?.filter(bid => 
    (bid?.bidderId?._id === user?._id || bid?.offeredPerkId?.userId?._id === user?._id) && 
    bid?.status === 'rejected'
  ) || [];

  const handleStartEdit = (bid) => {
    setEditingBid(bid._id);
    setEditQuantity(bid.quantity);
  };

  const handleCancelEdit = () => {
    setEditingBid(null);
    setEditQuantity(1);
  };

  const handleUpdateBid = async (bid) => {
    try {
      await showToast.promise(
        dispatch(updateBid({ bidId: bid._id, quantity: editQuantity })).unwrap(),
        {
          loading: 'Updating bid...',
          success: 'Bid updated successfully',
          error: 'Failed to update bid'
        }
      );
      setEditingBid(null);
      setEditQuantity(1);
    } catch (error) {
      console.error('Error updating bid:', error);
    }
  };

  const handleDeleteBid = async (bidId) => {
    try {
      await showToast.promise(
        dispatch(deleteBid(bidId)).unwrap(),
        {
          loading: 'Deleting bid...',
          success: 'Bid deleted successfully',
          error: 'Failed to delete bid'
        }
      );
    } catch (error) {
      console.error('Error deleting bid:', error);
    }
  };

  const handleAcceptBid = async (bid) => {
    try {
      await showToast.promise(
        dispatch(acceptBid(bid._id)).unwrap(),
        {
          loading: 'Accepting bid...',
          success: 'Bid accepted successfully',
          error: 'Failed to accept bid'
        }
      );
    } catch (error) {
      console.error('Error accepting bid:', error);
    }
  };

  const handleRejectBid = async (bid) => {
    try {
      await showToast.promise(
        dispatch(rejectBid(bid._id)).unwrap(),
        {
          loading: 'Rejecting bid...',
          success: 'Bid rejected successfully',
          error: 'Failed to reject bid'
        }
      );
    } catch (error) {
      console.error('Error rejecting bid:', error);
    }
  };

  const BidCard = ({ bid, type }) => {
    if (!bid || !user) return null;

    const isPlacedBid = bid.bidderId?._id === user._id;
    const exchangeValue = bid.quantity * (bid.bidPerkId?.estimatedValue || 0);
    const isEditing = editingBid === bid._id;

    return (
      <Card className="hover:shadow-lg transition-shadow duration-300">
        <CardHeader>
          <CardTitle className="flex justify-between items-start">
            <span>{isPlacedBid ? "Your bid" : "Received bid"}</span>
            <span className="text-sm font-normal text-gray-500">
              {new Date(bid.createdAt).toLocaleDateString()}
            </span>
          </CardTitle>
          <CardDescription>
            Status: <span className="font-medium text-blue-600">Pending</span>
          </CardDescription>
        </CardHeader>

        <CardContent className="space-y-4">
          <div className="flex justify-between items-start border-b pb-4">
            <div>
              <p className="font-medium text-gray-900">{bid.bidPerkId?.title}</p>
              <p className="text-sm text-gray-600">Quantity: {bid.quantity}x</p>
              <p className="text-sm text-gray-600">Value: ${exchangeValue}</p>
            </div>
            <div className="text-right">
              <p className="text-sm text-gray-600">In exchange for</p>
              <p className="font-medium text-gray-900">{bid.offeredPerkId?.title}</p>
              <p className="text-sm text-gray-600">Value: ${bid.offeredPerkId?.estimatedValue}</p>
            </div>
          </div>

          <div className="text-sm text-gray-600">
            {isPlacedBid ? (
              <p>Sent to: {bid.offeredPerkId?.userId?.name}</p>
            ) : (
              <p>From: {bid.bidderId?.name}</p>
            )}
          </div>
        </CardContent>

        <CardFooter className="justify-end space-x-2">
          {type === 'placed' && !isEditing && (
            <>
              <button
                onClick={() => handleStartEdit(bid)}
                className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeleteBid(bid._id)}
                className="px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </>
          )}

          {type === 'placed' && isEditing && (
            <div className="flex items-center space-x-2 w-full">
              <input
                type="number"
                min="1"
                value={editQuantity}
                onChange={(e) => setEditQuantity(parseInt(e.target.value))}
                className="w-24 px-2 py-1 border rounded-md"
              />
              <button
                onClick={() => handleUpdateBid(bid)}
                className="px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Save
              </button>
              <button
                onClick={handleCancelEdit}
                className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
            </div>
          )}

          {type === 'received' && (
            <>
              <button
                onClick={() => handleAcceptBid(bid)}
                className="px-3 py-1.5 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
              >
                Accept
              </button>
              <button
                onClick={() => handleRejectBid(bid)}
                className="px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
              >
                Reject
              </button>
            </>
          )}
        </CardFooter>
      </Card>
    );
  };

  const EmptyState = ({ message }) => (
    <div className="text-center py-12 bg-gray-50 rounded-lg">
      <h3 className="text-lg font-medium text-gray-900 mb-2">No bids to show</h3>
      <p className="text-gray-600">{message}</p>
    </div>
  );

  if (!user) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 text-center">
        Loading user data...
      </div>
    );
  }

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 text-center">
        Loading bids...
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="space-y-8">
        <section>
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Bids You've Placed</h2>
          {placedBids.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {placedBids.map(bid => (
                <BidCard key={bid._id} bid={bid} type="placed" />
              ))}
            </div>
          ) : (
            <EmptyState message="You haven't placed any bids yet. Browse the marketplace to find perks to exchange!" />
          )}
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Bids You've Received</h2>
          {receivedBids.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {receivedBids.map(bid => (
                <BidCard key={bid._id} bid={bid} type="received" />
              ))}
            </div>
          ) : (
            <EmptyState message="You haven't received any bids yet. Add more perks to attract exchanges!" />
          )}
        </section>

        {rejectedBids.length > 0 && (
          <section>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Rejected Bids</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {rejectedBids.map(bid => (
                <BidCard key={bid._id} bid={bid} type="rejected" />
              ))}
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default MyBids;