// frontend/src/components/RegistrationSuccess.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardTitle } from "./ui/card";

const RegistrationSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Card className="max-w-md mx-auto">
        <CardHeader>
          <CardTitle className="text-center">Check Your Email</CardTitle>
        </CardHeader>
        <CardContent className="text-center">
          <div className="bg-green-50 rounded-lg p-4 mb-6">
            <div className="text-green-600 text-5xl mb-4">✓</div>
            <h3 className="text-lg font-medium text-green-800">Registration Successful!</h3>
            <p className="mt-2 text-sm text-green-600">
              We've sent a verification link to your email address.
            </p>
          </div>
          
          <p className="text-gray-600 mb-6">
            Please check your inbox and click the verification link to complete your registration.
          </p>
          
          <div className="space-y-4">
            <button
              onClick={() => navigate('/login')}
              className="w-full px-4 py-2 text-sm font-medium text-blue-600 bg-white border border-blue-600 rounded-md hover:bg-blue-50"
            >
              Return to Login
            </button>
            
            <button
              onClick={() => navigate('/resend-verification')}
              className="w-full px-4 py-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Resend Verification Email
            </button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default RegistrationSuccess;