import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardHeader, CardContent, CardFooter, CardTitle, CardDescription } from './ui/card';
import RatingModal from './RatingModal';
import Chat from './Chat';
import { showToast } from '../utils/toast';
import SupportModal from './SupportModal';
import { 
  fetchExchanges, 
  updateExchange, 
  initiateCompletion, 
  confirmCompletion 
} from '../redux/slices/exchangeSlice';
import { createSupportTicket } from '../redux/slices/supportSlice';
import { fetchUnreadCounts } from '../redux/slices/chatSlice';
import { 
  Gift, 
  MessageCircle, 
  Clock, 
  CheckCircle2, 
  AlertCircle, 
  Plus, 
  Star 
} from 'lucide-react';

const ExchangeCard = ({ exchange, currentUser, onInitiateChat, onMarkComplete, onExtendTimeline, onRateTransaction, onRequestSupport }) => {
  const [extendDays, setExtendDays] = useState('');
  const unreadCounts = useSelector((state) => state.chat.unreadCounts);
  const unreadCount = unreadCounts[exchange._id] || 0;

  const isUserBidder = exchange.bidId.bidderId._id === currentUser._id;
  const otherUser = isUserBidder 
    ? exchange.bidId.offeredPerkId.userId 
    : exchange.bidId.bidderId;
  const isUserInitiator = exchange.completionInitiator === currentUser._id;

  const userRating = isUserBidder ? exchange.ratings?.bidder : exchange.ratings?.offerer;
  const otherUserRating = isUserBidder ? exchange.ratings?.offerer : exchange.ratings?.bidder;
  const canRate = exchange.status === 'completed' && !userRating?.rating;

  const statusBadges = {
    ongoing: "bg-blue-100 text-blue-800",
    pending_completion: "bg-yellow-100 text-yellow-800",
    completed: "bg-green-100 text-green-800"
  };

  const getBadgeIcon = () => {
    switch (exchange.status) {
      case 'ongoing': return <Clock className="w-4 h-4" />;
      case 'pending_completion': return <AlertCircle className="w-4 h-4" />;
      case 'completed': return <CheckCircle2 className="w-4 h-4" />;
      default: return null;
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Card className="group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
      <CardHeader className="flex flex-row items-start justify-between space-y-0 pb-2">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Gift className="w-6 h-6 text-blue-500" />
          </div>
          <div>
            <CardTitle className="text-lg font-semibold">
              {exchange.bidId.bidPerkId.title}
            </CardTitle>
            <CardDescription>
              Exchange with {otherUser.name} ({otherUser.company})
            </CardDescription>
          </div>
        </div>
        <div className={`flex items-center gap-2 px-3 py-1 rounded-full text-sm font-medium ${statusBadges[exchange.status]}`}>
          {getBadgeIcon()}
          <span>
            {exchange.status === 'pending_completion' ? 'Pending Completion' : 
             exchange.status.charAt(0).toUpperCase() + exchange.status.slice(1)}
          </span>
        </div>
      </CardHeader>

      <CardContent className="space-y-4">
        <div className="flex justify-between items-start border-b pb-4">
          <div>
            <p className="text-sm text-gray-600">Your part:</p>
            <p className="font-medium">{exchange.bidId.quantity}x {exchange.bidId.bidPerkId.title}</p>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-600">In exchange for:</p>
            <p className="font-medium">{exchange.bidId.offeredPerkId.title}</p>
          </div>
        </div>

        <div>
          <p className="text-sm text-gray-600">
            Completion Date: {formatDate(exchange.completionDate)}
          </p>
          <p className="text-sm text-gray-600 mt-2">
            Logistics: {exchange.logistics}
          </p>
        </div>

        {exchange.status === 'completed' && (
          <div className="border-t pt-4">
            <h4 className="font-medium text-gray-900 mb-3">Exchange Ratings</h4>
            {userRating?.rating ? (
              <div className="flex items-center gap-2 mb-2">
                <span className="text-sm text-gray-600">Your rating:</span>
                <div className="flex items-center text-yellow-400">
                  {Array.from({ length: userRating.rating }).map((_, i) => (
                    <Star key={i} className="w-4 h-4 fill-current" />
                  ))}
                </div>
                {userRating.comment && (
                  <p className="text-sm text-gray-600">"{userRating.comment}"</p>
                )}
              </div>
            ) : (
              canRate && (
                <button
                  onClick={() => onRateTransaction(exchange)}
                  className="text-sm text-blue-600 hover:text-blue-700"
                >
                  Rate this exchange
                </button>
              )
            )}
            
            {otherUserRating?.rating && (
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-600">{otherUser.name}'s rating:</span>
                <div className="flex items-center text-yellow-400">
                  {Array.from({ length: otherUserRating.rating }).map((_, i) => (
                    <Star key={i} className="w-4 h-4 fill-current" />
                  ))}
                </div>
                {otherUserRating.comment && (
                  <p className="text-sm text-gray-600">"{otherUserRating.comment}"</p>
                )}
              </div>
            )}
          </div>
        )}
      </CardContent>

      <CardFooter className="flex flex-wrap gap-3">
        <button
          onClick={() => onInitiateChat(exchange)}
          className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 relative"
        >
          <MessageCircle className="w-4 h-4 mr-2" />
          Chat with {otherUser.name}
          {unreadCount > 0 && (
            <span className="absolute -top-2 -right-2 flex h-5 w-5 items-center justify-center rounded-full bg-red-600 text-xs text-white">
              {unreadCount > 9 ? '9+' : unreadCount}
            </span>
          )}
        </button>

        {exchange.status === 'ongoing' && (
          <>
            <button
              onClick={() => onMarkComplete(exchange._id)}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
            >
              <CheckCircle2 className="w-4 h-4 mr-2" />
              Mark as Complete
            </button>

            <div className="flex items-center gap-2">
              <input
                type="number"
                placeholder="Days"
                value={extendDays}
                onChange={(e) => setExtendDays(e.target.value)}
                className="w-20 px-2 py-1 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                min="1"
              />
              <button
                onClick={() => {
                  onExtendTimeline(exchange._id, parseInt(extendDays));
                  setExtendDays('');
                }}
                disabled={!extendDays}
                className="flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
              >
                <Plus className="w-4 h-4 mr-2" />
                Extend
              </button>
            </div>
          </>
        )}

        {exchange.status === 'pending_completion' && !isUserInitiator && (
          <button
            onClick={() => onMarkComplete(exchange._id)}
            className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
          >
            <CheckCircle2 className="w-4 h-4 mr-2" />
            Confirm Completion
          </button>
        )}

        <button
          onClick={() => onRequestSupport(exchange)}
          className="flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          <AlertCircle className="w-4 h-4 mr-2" />
          Request Support
        </button>
      </CardFooter>
    </Card>
  );
};

const EmptyState = ({ message }) => (
  <div className="text-center py-12 bg-gray-50 rounded-lg">
    <Gift className="w-12 h-12 text-gray-400 mx-auto mb-4" />
    <h3 className="text-lg font-medium text-gray-900 mb-2">No exchanges to show</h3>
    <p className="text-gray-600">{message}</p>
  </div>
);

const AcceptedBids = () => {
  const dispatch = useDispatch();
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState(null);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [activeChatExchange, setActiveChatExchange] = useState(null);

  const currentUser = useSelector((state) => state.auth.user);
  const exchanges = useSelector((state) => state.exchanges.items);
  const loading = useSelector((state) => state.exchanges.loading);

  useEffect(() => {
    dispatch(fetchExchanges());
    dispatch(fetchUnreadCounts());

    const pollInterval = setInterval(() => {
      dispatch(fetchUnreadCounts());
    }, 30000);

    return () => clearInterval(pollInterval);
  }, [dispatch]);

  const ongoingExchanges = exchanges.filter(exchange => exchange.status === 'ongoing');
  const pendingCompletionExchanges = exchanges.filter(exchange => exchange.status === 'pending_completion');
  const completedExchanges = exchanges.filter(exchange => exchange.status === 'completed');

  const handleExtendTimeline = async (exchangeId, days) => {
    if (days && days > 0) {
      const newCompletionDate = new Date();
      newCompletionDate.setDate(newCompletionDate.getDate() + parseInt(days));
      try {
        await showToast.promise(
          dispatch(updateExchange({ 
            id: exchangeId, 
            completionDate: newCompletionDate.toISOString() 
          })).unwrap(),
          {
            loading: 'Extending timeline...',
            success: 'Timeline extended successfully!',
            error: 'Failed to extend timeline'
          }
        );
      } catch (error) {
        console.error('Error extending timeline:', error);
      }
    }
  };

  const handleMarkComplete = async (exchangeId) => {
    try {
      const exchange = exchanges.find(ex => ex._id === exchangeId);
      if (!exchange) return;

      if (exchange.status === 'ongoing') {
        await showToast.promise(
          dispatch(initiateCompletion(exchangeId)).unwrap(),
          {
            loading: 'Initiating completion...',
            success: 'Completion request sent',
            error: 'Failed to initiate completion'
          }
        );
      } else if (exchange.status === 'pending_completion') {
        await showToast.promise(
          dispatch(confirmCompletion(exchangeId)).unwrap(),
          {
            loading: 'Confirming completion...',
            success: 'Exchange completed successfully!',
            error: 'Failed to confirm completion'
          }
        );
      }
    } catch (error) {
      console.error('Error marking exchange as complete:', error);
    }
  };

  const handleRateTransaction = (exchange) => {
    setSelectedExchange(exchange);
    setIsRatingModalOpen(true);
  };

  const handleSubmitRating = async (ratingData) => {
    try {
      await showToast.promise(
        dispatch(updateExchange({
          id: ratingData.exchangeId,
          rating: ratingData.rating,
          comment: ratingData.comment
        })).unwrap(),
        {
          loading: 'Submitting rating...',
          success: 'Rating submitted successfully!',
          error: 'Failed to submit rating'
        }
      );
      setIsRatingModalOpen(false);
      setSelectedExchange(null);
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const handleRequestSupport = (exchange) => {
    setSelectedExchange(exchange);
    setIsSupportModalOpen(true);
  };

  const handleSubmitSupport = async ({ exchangeId, description }) => {
    try {
      await showToast.promise(
        dispatch(createSupportTicket({ exchangeId, description })).unwrap(),
        {
          loading: 'Submitting support request...',
          success: 'Support request submitted successfully!',
          error: 'Failed to submit request'
        }
      );
      setIsSupportModalOpen(false);
      setSelectedExchange(null);
    } catch (error) {
      console.error('Error submitting support request:', error);
    }
  };

if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="mb-8">
        <section className="mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Ongoing Exchanges</h2>
          {ongoingExchanges.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {ongoingExchanges.map(exchange => (
                <ExchangeCard
                  key={exchange._id}
                  exchange={exchange}
                  currentUser={currentUser}
                  onInitiateChat={setActiveChatExchange}
                  onMarkComplete={handleMarkComplete}
                  onExtendTimeline={handleExtendTimeline}
                  onRateTransaction={handleRateTransaction}
                  onRequestSupport={handleRequestSupport}
                />
              ))}
            </div>
          ) : (
            <EmptyState message="You don't have any ongoing exchanges at the moment." />
          )}
        </section>

        {pendingCompletionExchanges.length > 0 && (
          <section className="mb-8">
            <h2 className="text-3xl font-bold text-gray-900 mb-8">Pending Completion</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {pendingCompletionExchanges.map(exchange => (
                <ExchangeCard
                  key={exchange._id}
                  exchange={exchange}
                  currentUser={currentUser}
                  onInitiateChat={setActiveChatExchange}
                  onMarkComplete={handleMarkComplete}
                  onExtendTimeline={handleExtendTimeline}
                  onRateTransaction={handleRateTransaction}
                  onRequestSupport={handleRequestSupport}
                />
              ))}
            </div>
          </section>
        )}

        <section>
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Completed Exchanges</h2>
          {completedExchanges.length > 0 ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {completedExchanges.map(exchange => (
                <ExchangeCard
                  key={exchange._id}
                  exchange={exchange}
                  currentUser={currentUser}
                  onInitiateChat={setActiveChatExchange}
                  onMarkComplete={handleMarkComplete}
                  onExtendTimeline={handleExtendTimeline}
                  onRateTransaction={handleRateTransaction}
                  onRequestSupport={handleRequestSupport}
                />
              ))}
            </div>
          ) : (
            <EmptyState message="You haven't completed any exchanges yet." />
          )}
        </section>
      </div>

      <RatingModal
        isOpen={isRatingModalOpen}
        onClose={() => setIsRatingModalOpen(false)}
        onSubmit={handleSubmitRating}
        exchange={selectedExchange}
        currentUser={currentUser}
      />

      <SupportModal
        isOpen={isSupportModalOpen}
        onClose={() => setIsSupportModalOpen(false)}
        onSubmit={handleSubmitSupport}
        exchange={selectedExchange}
        currentUser={currentUser}
      />

      {activeChatExchange && (
        <Chat
          currentUser={currentUser}
          otherUser={activeChatExchange.bidId.bidderId._id === currentUser._id 
            ? activeChatExchange.bidId.offeredPerkId.userId 
            : activeChatExchange.bidId.bidderId}
          exchangeId={activeChatExchange._id}
          onClose={() => setActiveChatExchange(null)}
        />
      )}
    </div>
  );
};

export default AcceptedBids;