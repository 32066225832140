import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFriends, getPendingRequests, respondToRequest, removeFriend, sendFriendRequest } from '../redux/slices/friendSlice';
import { showToast } from '../utils/toast';
import api from '../utils/api';
import { 
  Card, 
  CardHeader, 
  CardContent, 
  CardTitle,
  CardDescription,
  CardFooter 
} from "./ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

const FriendsList = () => {
  const dispatch = useDispatch();
  const { friends, pendingRequests, loading } = useSelector((state) => state.friends);
  const [showPending, setShowPending] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [searchedUser, setSearchedUser] = useState(null);
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      dispatch(getFriends());
      dispatch(getPendingRequests());
    };

    fetchData();
    const interval = setInterval(fetchData, 30000); // Poll every 30 seconds

    return () => clearInterval(interval);
  }, [dispatch]);

  const handleRespondToRequest = async (requestId, accept) => {
    try {
      await showToast.promise(
        dispatch(respondToRequest({ requestId, accept })).unwrap(),
        {
          loading: accept ? 'Accepting request...' : 'Declining request...',
          success: accept ? 'Friend request accepted!' : 'Friend request declined',
          error: 'Failed to process request'
        }
      );
    } catch (error) {
      console.error('Error responding to friend request:', error);
    }
  };

  const handleRemoveFriend = async (friendId) => {
    if (window.confirm('Are you sure you want to remove this friend?')) {
      try {
        await showToast.promise(
          dispatch(removeFriend(friendId)).unwrap(),
          {
            loading: 'Removing friend...',
            success: 'Friend removed',
            error: 'Failed to remove friend'
          }
        );
      } catch (error) {
        console.error('Error removing friend:', error);
      }
    }
  };

  const handleSearchByInviteCode = async (e) => {
    e.preventDefault();
    if (!inviteCode.trim()) return;

    setSearching(true);
    try {
      const response = await api.get(`/users/invite/${inviteCode.trim()}`);
      setSearchedUser(response.data);
    } catch (error) {
      showToast.error(error.response?.data?.message || 'User not found');
      setSearchedUser(null);
    } finally {
      setSearching(false);
    }
  };

  const handleSendFriendRequest = async (userId) => {
    try {
      await showToast.promise(
        dispatch(sendFriendRequest(userId)).unwrap(),
        {
          loading: 'Sending friend request...',
          success: 'Friend request sent successfully!',
          error: 'Failed to send friend request'
        }
      );
      setSearchedUser(null);
      setInviteCode('');
    } catch (error) {
      console.error('Error sending friend request:', error);
    }
  };

  const FriendCard = ({ friend }) => {
    const otherUser = friend.requesterId._id === friend.recipientId._id
      ? friend.recipientId
      : friend.requesterId;

    return (
      <div className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50">
        <div className="flex items-center gap-4">
          <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
            <span className="text-blue-600 font-semibold">
              {otherUser.name.charAt(0).toUpperCase()}
            </span>
          </div>
          <div>
            <Link 
              to={`/profile/${otherUser._id}`}
              className="font-medium text-blue-600 hover:underline"
            >
              {otherUser.username}
            </Link>
            <p className="text-sm text-gray-600">{otherUser.company}</p>
            {otherUser.averageRating && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <div className="flex items-center gap-1 cursor-help">
                      <span className="text-yellow-400">★</span>
                      <span>{otherUser.averageRating.toFixed(1)}</span>
                      <span className="text-gray-500">
                        ({otherUser.totalRatings})
                      </span>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="p-2">
                      <p className="font-medium mb-1">Exchange History</p>
                      <p className="text-sm">Successfully completed {otherUser.totalRatings} exchanges</p>
                      <p className="text-sm text-gray-400">Average rating: {otherUser.averageRating.toFixed(1)} / 5</p>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        </div>
        <button
          onClick={() => handleRemoveFriend(otherUser._id)}
          className="px-3 py-1.5 text-sm text-red-600 border border-red-600 rounded-md hover:bg-red-50"
        >
          Remove
        </button>
      </div>
    );
  };

  const RequestCard = ({ request }) => (
    <div className="flex items-center justify-between p-4 border rounded-lg">
      <div>
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
            <span className="text-blue-600 font-semibold">
              {request.requesterId.name.charAt(0).toUpperCase()}
            </span>
          </div>
          <div>
            <p className="font-medium">{request.requesterId.username}</p>
            <p className="text-sm text-gray-600">{request.requesterId.company}</p>
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        <button
          onClick={() => handleRespondToRequest(request._id, true)}
          className="px-3 py-1.5 text-sm text-white bg-green-600 rounded-md hover:bg-green-700"
        >
          Accept
        </button>
        <button
          onClick={() => handleRespondToRequest(request._id, false)}
          className="px-3 py-1.5 text-sm text-white bg-red-600 rounded-md hover:bg-red-700"
        >
          Decline
        </button>
      </div>
    </div>
  );

  const EmptyState = () => (
    <div className="text-center py-12 bg-gray-50 rounded-lg">
      <p className="text-gray-600 mb-4">You haven't added any friends yet</p>
      <p className="text-sm text-gray-500">
        Find other users by browsing the marketplace, searching by company, or using an invite code
      </p>
    </div>
  );

  if (loading && !friends.length && !pendingRequests.length) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">My Friends</h1>
        {pendingRequests.length > 0 && (
          <button
            onClick={() => setShowPending(!showPending)}
            className="flex items-center gap-2 px-4 py-2 bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200"
          >
            {showPending ? 'View Friends' : 'View Requests'}
            {!showPending && (
              <span className="flex h-5 w-5 items-center justify-center text-xs bg-blue-600 text-white rounded-full">
                {pendingRequests.length}
              </span>
            )}
          </button>
        )}
      </div>

      {/* Invite Code Search Section */}
      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Find User by Invite Code</CardTitle>
          <CardDescription>Enter an invite code to connect with a private profile</CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSearchByInviteCode} className="flex gap-2">
            <input
              type="text"
              value={inviteCode}
              onChange={(e) => setInviteCode(e.target.value)}
              placeholder="Enter invite code"
              className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              disabled={searching || !inviteCode.trim()}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-blue-300"
            >
              {searching ? 'Searching...' : 'Search'}
            </button>
          </form>

          {searchedUser && (
            <div className="mt-4 p-4 border rounded-lg">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="font-medium">{searchedUser.username}</h3>
                  <p className="text-sm text-gray-600">{searchedUser.company}</p>
                  {searchedUser.averageRating && (
                    <div className="flex items-center gap-1 mt-1">
                      <span className="text-yellow-400">★</span>
                      <span>{searchedUser.averageRating.toFixed(1)}</span>
                      <span className="text-gray-500">({searchedUser.totalRatings} ratings)</span>
                    </div>
                  )}
                </div>
                <button
                  onClick={() => handleSendFriendRequest(searchedUser._id)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Send Friend Request
                </button>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      {showPending ? (
        <Card>
          <CardHeader>
            <CardTitle>Pending Friend Requests</CardTitle>
            <CardDescription>
              {pendingRequests.length} pending request{pendingRequests.length !== 1 ? 's' : ''}
            </CardDescription>
          </CardHeader>
          <CardContent>
            {pendingRequests.length > 0 ? (
              <div className="space-y-4">
                {pendingRequests.map(request => (
                  <RequestCard key={request._id} request={request} />
                ))}
              </div>
            ) : (
              <p className="text-gray-500 text-center py-8">
                No pending friend requests
              </p>
            )}
          </CardContent>
        </Card>
      ) : (
        <div className="space-y-4">
          {friends.length > 0 ? (
            friends.map(friend => (
              <FriendCard key={friend._id} friend={friend} />
            ))
          ) : (
            <EmptyState />
          )}
        </div>
      )}
    </div>
  );
};

export default FriendsList;