// frontend/src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import perkReducer from './slices/perkSlice';
import bidReducer from './slices/bidSlice';
import exchangeReducer from './slices/exchangeSlice';
import supportReducer from './slices/supportSlice';
import chatReducer from './slices/chatSlice';
import friendReducer from './slices/friendSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    perks: perkReducer,
    bids: bidReducer,
    exchanges: exchangeReducer,
    support: supportReducer,
    chat: chatReducer,
    friends: friendReducer
  },
});